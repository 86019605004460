var __extensible_get__ = require("extensible-runtime").immutable;

import axios from 'axios';
export var getSite = function getSite(id) {
  return axios.get("/sites/".concat(id));
};
export var getSiteList = function getSiteList() {
  return axios.get('/sites');
};
export var addSite = function addSite(payload) {
  return axios.post('/sites', payload);
};
export var updateSite = function updateSite(payload) {
  return axios.put("/sites/".concat(payload.Site.ID), payload);
};
export var deleteSite = function deleteSite(id) {
  return axios["delete"]("/sites/".concat(id));
};
export var fetchSiteLogo = function fetchSiteLogo(id) {
  return axios.get("/sites/SiteLogo/".concat(id));
};
export var updateBannerViewed = function updateBannerViewed() {
  return axios.post("/sites/Banner/".concat(1));
};