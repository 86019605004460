var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var initPayload = function initPayload() {
  return Immutable.fromJS({
    Name: '',
    ActiveSiteRFID: false,
    PlusSite: false,
    Description: '',
    TemperatureAlertRange: '',
    Address1: '',
    Address2: '',
    Managed: false,
    ManagerIDs: [],
    PhotectorUser: '',
    City: '',
    State: '',
    Zip: '',
    Latitude: 0,
    Longitude: 0,
    Timezone: '',
    WelcomeMessage: '',
    SpotMessage: '',
    PullMessage: '',
    Scale: 0,
    LocationEditType: 0,
    SiteType: 0,
    SpecialDirectionEdit: false,
    SpecialTrailerStatusEdit: false,
    SpecialShipmentNumberEdit: false,
    BannerMessage: null
  });
};
export var sitePayload = function sitePayload() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initPayload();
  return {
    Site: {
      Name: obj.get('Name'),
      Description: obj.get('Description'),
      Managed: obj.get('Managed'),
      ManagerIDs: obj.get('ManagerIDs'),
      PhotectorUser: obj.get('PhotectorUser'),
      TemperatureAlertRange: +obj.get('TemperatureAlertRange'),
      Address1: obj.get('Address1'),
      Address2: obj.get('Address2'),
      ID: obj.get('ID'),
      City: obj.get('City'),
      State: obj.get('State'),
      Zip: obj.get('Zip'),
      Latitude: obj.get('Latitude'),
      Longitude: obj.get('Longitude'),
      Timezone: obj.get('Timezone'),
      ActiveSiteRFID: obj.get('ActiveSiteRFID'),
      PlusSite: obj.get('PlusSite'),
      WelcomeMessage: obj.get('WelcomeMessage'),
      SpotMessage: obj.get('SpotMessage'),
      PullMessage: obj.get('PullMessage'),
      Scale: obj.get('Scale'),
      LocationEditType: obj.get('LocationEditType'),
      SiteType: obj.get('SiteType'),
      SpecialDirectionEdit: obj.get('SpecialDirectionEdit'),
      SpecialTrailerStatusEdit: obj.get('SpecialTrailerStatusEdit'),
      SpecialShipmentNumberEdit: obj.get('SpecialShipmentNumberEdit'),
      HardTagSite: obj.get('HardTagSite'),
      BannerMessage: obj.get('BannerMessage'),
      ProductProtectionPartialTrailer: obj.get('ProductProtectionPartialTrailer')
    }
  };
};