var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var initFilters = function initFilters() {
  return Immutable.fromJS({
    skip: 0,
    take: 10,
    keyword: ''
  });
};
export var getScacList = function getScacList() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initFilters();
  return {
    skip: obj.get('skip'),
    take: obj.get('take'),
    keyword: obj.get('keyword')
  };
};
export var initPayload = function initPayload() {
  return Immutable.fromJS({
    Name: '',
    Value: '',
    Type: 0,
    Email1: '',
    Email2: '',
    AuditEmailsEnabled: false,
    ArrivalEmailsEnabled: false,
    DepartureEmailsEnabled: false,
    ID: 0
  });
};
export var scacPayload = function scacPayload() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initPayload();
  return {
    Scac: {
      Name: obj.get('Name'),
      Value: obj.get('Value'),
      Type: obj.get('Type'),
      Email1: obj.get('Email1'),
      Email2: obj.get('Email2'),
      AuditEmailsEnabled: obj.get('AuditEmailsEnabled'),
      ArrivalEmailsEnabled: obj.get('ArrivalEmailsEnabled'),
      DepartureEmailsEnabled: obj.get('DepartureEmailsEnabled'),
      ID: obj.get('ID')
    }
  };
};
export var importScacs = function importScacs(obj) {
  return {
    Scacs: obj.map(function (item) {
      return {
        Value: item.get('Value'),
        Name: item.get('Name'),
        Type: item.get('Type'),
        Email1: item.get('Email1'),
        Email2: item.get('Email2'),
        AuditEmailsEnabled: item.get('AuditEmailsEnabled'),
        ArrivalEmailsEnabled: item.get('ArrivalEmailsEnabled'),
        DepartureEmailsEnabled: item.get('DepartureEmailsEnabled')
      };
    }).toJS()
  };
};