var __extensible_get__ = require("extensible-runtime").immutable;

import axios from 'axios';
export var getActiveSession = function getActiveSession(type) {
  return axios.get("/audits/".concat(type));
}; // 1 - yard audit; 2 - product protection

export var getSessionList = function getSessionList() {
  return axios.get('/audits');
};
export var startSession = function startSession(payload) {
  return axios.post('/audits', payload);
};
export var closeSession = function closeSession(id) {
  return axios.put("/audits/".concat(id, "/close"));
};
export var signOffTrailer = function signOffTrailer(payload) {
  return axios.post('/audittrailers', payload);
}; // check /uncheck a trailer