var __extensible_get__ = require("extensible-runtime").immutable;

import axios from 'axios';
export var login = function login(payload) {
  return axios.post('/users/login', payload);
};
export var logout = function logout(payload) {
  return axios.post('/users/logout', payload);
};
export var checkToken = function checkToken() {
  return axios.get('/users/token');
};
export var getPhotectorToken = function getPhotectorToken() {
  return axios.get('/users/PhotectorKey');
};
export var addUser = function addUser(payload) {
  return axios.post('/users', payload);
};
export var updateUser = function updateUser(payload) {
  return axios.put("/users/".concat(payload.User.ID), payload);
};
export var deleteUser = function deleteUser(id) {
  return axios["delete"]("/users/".concat(id));
};
export var getUser = function getUser(payload) {
  return axios.get("/users/".concat(payload.ID));
};
export var getUserList = function getUserList(payload) {
  var name = payload.name ? "keyword=".concat(payload.name, "&") : '';
  var status = payload.status >= 0 ? "status=".concat(payload.status, "&") : '';
  var roles = payload.roles.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, role) {
    return acc + "roles=".concat(role, "&");
  }, '');
  /**extra condition added to differentiate filters b/w user & driver dockgroup */

  var sites = '';
  if (payload.sites != undefined) sites = payload.sites.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, site) {
    return acc + "sites=".concat(site, "&");
  }, '');
  var skip = "skip=".concat(payload.skip, "&");
  var take = "take=".concat(payload.take, "&");
  var query = name + status + roles + sites + skip + take;
  return axios.get("/users".concat(query.length ? '?' : '').concat(query.slice(0, query.length - 1)));
};
export var updateActiveSite = function updateActiveSite(payload) {
  return axios.put('/users/activeSite', payload);
};