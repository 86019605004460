var __extensible_get__ = require("extensible-runtime").immutable;

import axios from 'axios';
export var addFreight = function addFreight(payload) {
  return axios.post('/freights', payload);
};
export var updateFreight = function updateFreight(payload) {
  return axios.put("freights/".concat(payload.Freight.ID), payload);
};
export var getFreights = function getFreights(payload) {
  return axios.get('/freights', payload);
};
export var getFreight = function getFreight(id) {
  return axios.get("/freights/".concat(id));
};
export var deleteFreight = function deleteFreight(id) {
  return axios["delete"]("/freights/".concat(id));
};