var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var types = {
  GET_SCALE_WEIGHT: "GET_SCALE_WEIGHT",
  REQUEST_GET_SCALE_WEIGHT: "REQUEST_GET_SCALE_WEIGHT",
  RECEIVE_GET_SCALE_WEIGHT: "RECEIVE_GET_SCALE_WEIGHT",
  RECEIVE_GET_SCALE_WEIGHT_FAIL: "RECEIVE_GET_SCALE_WEIGHT_FAIL"
};
export var actions = {
  getScaleWeight: function getScaleWeight() {
    return {
      type: types.GET_SCALE_WEIGHT
    };
  },
  requestGetScaleWeight: function requestGetScaleWeight() {
    return {
      type: types.REQUEST_GET_SCALE_WEIGHT
    };
  },
  receiveGetScaleWeight: function receiveGetScaleWeight(payload) {
    return {
      type: types.RECEIVE_GET_SCALE_WEIGHT,
      payload: payload
    };
  },
  receiveGetScaleWeightFail: function receiveGetScaleWeightFail() {
    return {
      type: types.RECEIVE_GET_SCALE_WEIGHT_FAIL
    };
  }
};
var initialState = Immutable.fromJS({
  weight: 0,
  weightLoader: false,
  scaleStatus: -1
});

var scale = function scale() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.REQUEST_GET_SCALE_WEIGHT:
      return state.set('weight', 0).set('weightLoader', true);

    case types.RECEIVE_GET_SCALE_WEIGHT:
      return state.set('scaleStatus', action.payload.ScaleStatus).set('weight', action.payload.Weight).set('weightLoader', false);

    case types.RECEIVE_GET_SCALE_WEIGHT_FAIL:
      return state.set('weightLoader', false);

    default:
      return state;
  }
};

export var selectors = {
  getWeight: function getWeight(state) {
    return state.scale.get('weight');
  },
  getWeightLoader: function getWeightLoader(state) {
    return state.scale.get('weightLoader');
  },
  getScaleStatus: function getScaleStatus(state) {
    return state.scale.get('scaleStatus');
  }
};
export default scale;