var __extensible_get__ = require("extensible-runtime").immutable;

import axios from 'axios';
export var getDockGroup = function getDockGroup(id) {
  return axios.get("/DockGroups/".concat(id));
};
export var getDockGroupList = function getDockGroupList() {
  return axios.get('/DockGroups');
};
export var addDockGroup = function addDockGroup(payload) {
  return axios.post('/DockGroups', payload);
};
export var updateDockGroup = function updateDockGroup(payload) {
  return axios.put("/DockGroups/".concat(payload.DockGroup.ID), payload);
};
export var deleteDockGroup = function deleteDockGroup(id) {
  return axios["delete"]("/DockGroups/".concat(id));
};