var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var types = {
  UPDATE_DRIVER_USER: 'UPDATE_DRIVER_USER',
  REQUEST_UPDATE_DRIVER_USER: 'REQUEST_UPDATE_DRIVER_USER',
  RECEIVE_UPDATE_DRIVER_USER: 'RECEIVE_UPDATE_DRIVER_USER',
  RECEIVE_UPDATE_DRIVER_USER_FAIL: 'RECEIVE_UPDATE_DRIVER_USER_FAIL',
  GET_DRIVER_USER_LIST: 'GET_DRIVER_USER_LIST',
  REQUEST_GET_DRIVER_USER_LIST: 'REQUEST_GET_DRIVER_USER_LIST',
  RECEIVE_GET_DRIVER_USER_LIST: 'RECEIVE_GET_DRIVER_USER_LIST',
  RECEIVE_GET_DRIVER_USER_LIST_FAIL: 'RECEIVE_GET_DRIVER_USER_LIST_FAIL'
};
export var actions = {
  updateDriverDockgroup: function updateDriverDockgroup(payload) {
    return {
      type: types.UPDATE_DRIVER_USER,
      payload: payload
    };
  },
  requestUpdateDriverDockgroup: function requestUpdateDriverDockgroup() {
    return {
      type: types.REQUEST_UPDATE_DRIVER_USER
    };
  },
  receiveUpdateDriverDockgroup: function receiveUpdateDriverDockgroup(user) {
    return {
      type: types.RECEIVE_UPDATE_DRIVER_USER,
      user: user
    };
  },
  receiveUpdateDriverDockgroupFail: function receiveUpdateDriverDockgroupFail() {
    return {
      type: types.RECEIVE_UPDATE_DRIVER_USER_FAIL
    };
  },
  getDriverUserList: function getDriverUserList(payload) {
    return {
      type: types.GET_DRIVER_USER_LIST,
      payload: payload
    };
  },
  requestGetDriverUserList: function requestGetDriverUserList() {
    return {
      type: types.REQUEST_GET_DRIVER_USER_LIST
    };
  },
  receiveGetDriverUserList: function receiveGetDriverUserList(users, itemsCount) {
    return {
      type: types.RECEIVE_GET_DRIVER_USER_LIST,
      users: users,
      itemsCount: itemsCount
    };
  },
  receiveGetDriverUserListFail: function receiveGetDriverUserListFail() {
    return {
      type: types.RECEIVE_GET_DRIVER_USER_LIST_FAIL
    };
  }
};
var initialState = Immutable.fromJS({
  users: [],
  listLoader: false,
  itemsCount: 0
});

var dockgroupdriver = function dockgroupdriver() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.REQUEST_UPDATE_DRIVER_USER:
      return state.set('dialogLoader', true);

    case types.RECEIVE_UPDATE_DRIVER_USER:
      return state.set('dialogLoader', false);

    case types.RECEIVE_UPDATE_DRIVER_USER_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_GET_DRIVER_USER_LIST:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_DRIVER_USER_LIST:
      return state.set('users', Immutable.fromJS(action.users)).set('itemsCount', action.itemsCount).set('listLoader', false);

    case types.RECEIVE_GET_DRIVER_USER_LIST_FAIL:
      return state.set('listLoader', false);

    default:
      return state;
  }
};

export var selectors = {
  getDriversList: function getDriversList(state) {
    return state.dockgroupdriver.get('users');
  },
  getItemsCount: function getItemsCount(state) {
    return state.dockgroupdriver.get('itemsCount');
  },
  getListLoader: function getListLoader(state) {
    return state.dockgroupdriver.get('listLoader');
  }
};
export default dockgroupdriver;