var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var initAddPayload = function initAddPayload() {
  return {
    Sites: [],
    TaskID: 0,
    Type: 0,
    IsPrivate: 0,
    Content: ''
  };
};
var initPayload = Immutable.fromJS({
  siteId: 0,
  taskId: 0
});
export var getMessageList = function getMessageList() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initPayload;
  return {
    siteID: obj.get('siteId'),
    taskID: obj.get('taskId')
  };
};
export var addSiteMessage = function addSiteMessage() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initAddPayload();
  return {
    Message: {
      Sites: obj.get('Sites').toJS(),
      Type: obj.get('Type'),
      IsPrivate: obj.get('IsPrivate') ? 1 : 0,
      Content: obj.get('Content')
    }
  };
};
export var addTaskMessage = function addTaskMessage() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initAddPayload();
  return {
    Message: {
      TaskID: obj.get('TaskID'),
      Type: obj.get('Type'),
      IsPrivate: obj.get('IsPrivate') ? 1 : 0,
      Content: obj.get('Content')
    }
  };
};