var __extensible_get__ = require("extensible-runtime").immutable;

import React from 'react';

function useInterval(callback, delay) {
  var intervalRef = React.useRef(null);
  var savedCallback = React.useRef(callback);
  React.useEffect(function () {
    savedCallback.current = callback;
  }, [callback]);
  React.useEffect(function () {
    var tick = function tick() {
      return savedCallback.current();
    };

    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(tick, delay);
      return function () {
        return window.clearInterval(intervalRef.current);
      };
    }
  }, [delay]);
  return intervalRef;
}

export default useInterval;