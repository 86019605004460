var __extensible_get__ = require("extensible-runtime").immutable;

import axios from 'axios';
export var getLookupList = function getLookupList(payload) {
  var types = payload.types.reduce(function (acc, type) {
    return acc + "lookupTypes=".concat(type, "&");
  }, '');
  var keyword = payload.keyword ? "keyword=".concat(payload.keyword, "&") : '';
  var query = types + keyword;
  return axios.get("/lookups".concat(query.length ? '?' : '').concat(query.slice(0, query.length - 1)));
};