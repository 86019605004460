var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
import { locationNameComparator } from '../utils';
export var types = {
  GET_LOCATION: 'GET_LOCATION',
  REQUEST_GET_LOCATION: 'REQUEST_GET_LOCATION',
  RECEIVE_GET_LOCATION: 'RECEIVE_GET_LOCATION',
  RECEIVE_GET_LOCATION_FAIL: 'RECEIVE_GET_LOCATION_FAIL',
  GET_LOCATION_LIST: 'GET_LOCATION_LIST',
  REQUEST_GET_LOCATION_LIST: 'REQUEST_GET_LOCATION_LIST',
  RECEIVE_GET_LOCATION_LIST: 'RECEIVE_GET_LOCATION_LIST',
  RECEIVE_GET_LOCATION_LIST_FAIL: 'RECEIVE_GET_LOCATION_LIST_FAIL',
  ADD_LOCATION: 'ADD_LOCATION',
  REQUEST_ADD_LOCATION: 'REQUEST_ADD_LOCATION',
  RECEIVE_ADD_LOCATION: 'RECEIVE_ADD_LOCATION',
  RECEIVE_ADD_LOCATION_FAIL: 'RECEIVE_ADD_LOCATION_FAIL',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  REQUEST_UPDATE_LOCATION: 'REQUEST_UPDATE_LOCATION',
  RECEIVE_UPDATE_LOCATION: 'RECEIVE_UPDATE_LOCATION',
  RECEIVE_UPDATE_LOCATION_FAIL: 'RECEIVE_UPDATE_LOCATION_FAIL',
  DELETE_LOCATION: 'DELETE_LOCATION',
  REQUEST_DELETE_LOCATION: 'REQUEST_DELETE_LOCATION',
  RECEIVE_DELETE_LOCATION: 'RECEIVE_DELETE_LOCATION',
  RECEIVE_DELETE_LOCATION_FAIL: 'RECEIVE_DELETE_LOCATION_FAIL',
  IMPORT_LOCATIONS: 'IMPORT_LOCATIONS',
  REQUEST_IMPORT_LOCATIONS: 'REQUEST_IMPORT_LOCATIONS',
  RECEIVE_IMPORT_LOCATIONS: 'RECEIVE_IMPORT_LOCATIONS',
  RECEIVE_IMPORT_LOCATIONS_FAIL: 'RECEIVE_IMPORT_LOCATIONS_FAIL',
  DOWNLOAD_CSV_EXAMPLE: 'DOWNLOAD_CSV_EXAMPLE',
  REQUEST_DOWNLOAD_CSV_EXAMPLE: 'REQUEST_DOWNLOAD_CSV_EXAMPLE',
  RECEIVE_DOWNLOAD_CSV_EXAMPLE: 'RECEIVE_DOWNLOAD_CSV_EXAMPLE',
  RECEIVE_DOWNLOAD_CSV_EXAMPLE_FAIL: 'RECEIVE_DOWNLOAD_CSV_EXAMPLE_FAIL',
  UPDATE_DOOR_STATUS: 'UPDATE_DOOR_STATUS',
  REQUEST_UPDATE_DOOR_STATUS: 'REQUEST_UPDATE_DOOR_STATUS',
  RECEIVE_UPDATE_DOOR_STATUS: 'RECEIVE_UPDATE_DOOR_STATUS',
  RECEIVE_UPDATE_DOOR_STATUS_FAIL: 'RECEIVE_UPDATE_DOOR_STATUS_FAIL'
};
export var actions = {
  getLocation: function getLocation(id) {
    return {
      type: types.GET_LOCATION,
      id: id
    };
  },
  requestGetLocation: function requestGetLocation() {
    return {
      type: types.REQUEST_GET_LOCATION
    };
  },
  receiveGetLocation: function receiveGetLocation(location) {
    return {
      type: types.RECEIVE_GET_LOCATION,
      location: location
    };
  },
  receiveGetLocationFail: function receiveGetLocationFail() {
    return {
      type: types.RECEIVE_GET_LOCATION_FAIL
    };
  },
  addLocation: function addLocation(payload, callback) {
    return {
      type: types.ADD_LOCATION,
      payload: payload,
      callback: callback
    };
  },
  requestAddLocation: function requestAddLocation() {
    return {
      type: types.REQUEST_ADD_LOCATION
    };
  },
  receiveAddLocation: function receiveAddLocation(location) {
    return {
      type: types.RECEIVE_ADD_LOCATION,
      location: location
    };
  },
  receiveAddLocationFail: function receiveAddLocationFail() {
    return {
      type: types.RECEIVE_ADD_LOCATION_FAIL
    };
  },
  updateLocation: function updateLocation(payload, callback) {
    return {
      type: types.UPDATE_LOCATION,
      payload: payload,
      callback: callback
    };
  },
  requestUpdateLocation: function requestUpdateLocation() {
    return {
      type: types.REQUEST_UPDATE_LOCATION
    };
  },
  receiveUpdateLocation: function receiveUpdateLocation(location) {
    return {
      type: types.RECEIVE_UPDATE_LOCATION,
      location: location
    };
  },
  receiveUpdateLocationFail: function receiveUpdateLocationFail() {
    return {
      type: types.RECEIVE_UPDATE_LOCATION_FAIL
    };
  },
  deleteLocation: function deleteLocation(id, callback) {
    return {
      type: types.DELETE_LOCATION,
      id: id,
      callback: callback
    };
  },
  requestDeleteLocation: function requestDeleteLocation() {
    return {
      type: types.REQUEST_DELETE_LOCATION
    };
  },
  receiveDeleteLocation: function receiveDeleteLocation(id) {
    return {
      type: types.RECEIVE_DELETE_LOCATION,
      id: id
    };
  },
  receiveDeleteLocationFail: function receiveDeleteLocationFail() {
    return {
      type: types.RECEIVE_DELETE_LOCATION_FAIL
    };
  },
  getLocationList: function getLocationList() {
    return {
      type: types.GET_LOCATION_LIST
    };
  },
  requestGetLocationList: function requestGetLocationList() {
    return {
      type: types.REQUEST_GET_LOCATION_LIST
    };
  },
  receiveGetLocationList: function receiveGetLocationList(locations) {
    return {
      type: types.RECEIVE_GET_LOCATION_LIST,
      locations: locations
    };
  },
  receiveGetLocationListFail: function receiveGetLocationListFail() {
    return {
      type: types.RECEIVE_GET_LOCATION_LIST_FAIL
    };
  },
  importLocations: function importLocations(payload, callback) {
    return {
      type: types.IMPORT_LOCATIONS,
      payload: payload,
      callback: callback
    };
  },
  requestImportLocations: function requestImportLocations() {
    return {
      type: types.REQUEST_IMPORT_LOCATIONS
    };
  },
  receiveImportLocations: function receiveImportLocations(location) {
    return {
      type: types.RECEIVE_IMPORT_LOCATIONS,
      location: location
    };
  },
  receiveImportLocationsFail: function receiveImportLocationsFail() {
    return {
      type: types.RECEIVE_IMPORT_LOCATIONS_FAIL
    };
  },
  downloadCSVExample: function downloadCSVExample(callback) {
    return {
      type: types.DOWNLOAD_CSV_EXAMPLE,
      callback: callback
    };
  },
  requestDownloadCSVExample: function requestDownloadCSVExample() {
    return {
      type: types.REQUEST_DOWNLOAD_CSV_EXAMPLE
    };
  },
  receiveDownloadCSVExample: function receiveDownloadCSVExample() {
    return {
      type: types.RECEIVE_DOWNLOAD_CSV_EXAMPLE
    };
  },
  receiveDownloadCSVExampleFail: function receiveDownloadCSVExampleFail() {
    return {
      type: types.RECEIVE_DOWNLOAD_CSV_EXAMPLE_FAIL
    };
  },
  updateDoorStatus: function updateDoorStatus(payload, callback) {
    return {
      type: types.UPDATE_DOOR_STATUS,
      payload: payload,
      callback: callback
    };
  },
  requestUpdateDoorStatus: function requestUpdateDoorStatus() {
    return {
      type: types.REQUEST_UPDATE_DOOR_STATUS
    };
  },
  receiveUpdateDoorStatus: function receiveUpdateDoorStatus() {
    return {
      type: types.RECEIVE_UPDATE_DOOR_STATUS
    };
  },
  receiveUpdateDoorStatusFail: function receiveUpdateDoorStatusFail() {
    return {
      type: types.RECEIVE_UPDATE_DOOR_STATUS_FAIL
    };
  }
};
var initialState = Immutable.fromJS({
  list: [],
  listLoader: false,
  buttonLoader: false,
  importLoader: false,
  dialogLoader: false,
  downloadLoader: false,
  oosStatusLoader: false
});

var receiveGetLocationList = function receiveGetLocationList(state, action) {
  return state.set('list', Immutable.fromJS(action.locations).sortBy(function (location) {
    return location.get('Name');
  }, locationNameComparator // comparator function
  ));
};

var receiveAddLocation = function receiveAddLocation(state, action) {
  if (!action.location) return state; // location might have been added to another site

  return state.update('list', function (list) {
    return list.push(Immutable.fromJS(action.location));
  });
};

var receiveUpdateLocation = function receiveUpdateLocation(state, action) {
  var index = state.get('list').findIndex(function (location) {
    return location.get('ID') == action.location.ID;
  });
  return state.setIn(['list', index], Immutable.fromJS(action.location));
};

var receiveDeleteLocation = function receiveDeleteLocation(state, action) {
  var index = state.get('list').findIndex(function (location) {
    return location.get('ID') === action.id;
  });
  return state.update('list', function (list) {
    return list["delete"](index);
  });
};

var location = function location() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.REQUEST_GET_LOCATION:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_LOCATION:
      return state.set('currentLocation', Immutable.fromJS(action.location || {})) // avoid setting null
      .set('editedLocation', Immutable.fromJS(action.location)).set('listLoader', false);

    case types.RECEIVE_GET_LOCATION_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_GET_LOCATION_LIST:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_LOCATION_LIST:
      return receiveGetLocationList(state, action).set('listLoader', false);

    case types.RECEIVE_GET_LOCATION_LIST_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_ADD_LOCATION:
      return state.set('buttonLoader', true);

    case types.RECEIVE_ADD_LOCATION:
      return receiveAddLocation(state, action).set('buttonLoader', false);

    case types.RECEIVE_ADD_LOCATION_FAIL:
      return state.set('buttonLoader', false);

    case types.REQUEST_UPDATE_LOCATION:
      return state.set('dialogLoader', true);

    case types.RECEIVE_UPDATE_LOCATION:
      return receiveUpdateLocation(state, action).set('dialogLoader', false);

    case types.RECEIVE_UPDATE_LOCATION_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_DELETE_LOCATION:
      return state.set('dialogLoader', true);

    case types.RECEIVE_DELETE_LOCATION:
      return receiveDeleteLocation(state, action).set('dialogLoader', false);

    case types.RECEIVE_DELETE_LOCATION_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_IMPORT_LOCATIONS:
      return state.set('importLoader', true);

    case types.RECEIVE_IMPORT_LOCATIONS:
      return state.set('importLoader', false);

    case types.RECEIVE_IMPORT_LOCATIONS_FAIL:
      return state.set('importLoader', false);

    case types.REQUEST_DOWNLOAD_CSV_EXAMPLE:
      return state.set('downloadLoader', true);

    case types.RECEIVE_DOWNLOAD_CSV_EXAMPLE:
      return state.set('downloadLoader', false);

    case types.RECEIVE_DOWNLOAD_CSV_EXAMPLE_FAIL:
      return state.set('downloadLoader', false);

    case types.REQUEST_UPDATE_DOOR_STATUS:
      return state.set('oosStatusLoader', true);

    case types.RECEIVE_UPDATE_DOOR_STATUS:
      return state.set('oosStatusLoader', false);

    case types.RECEIVE_UPDATE_DOOR_STATUS_FAIL:
      return state.set('oosStatusLoader', false);

    default:
      return state;
  }
};

export var selectors = {
  getLocations: function getLocations(state) {
    return state.location.get('list');
  },
  getListLoader: function getListLoader(state) {
    return state.location.get('listLoader');
  },
  getButtonLoader: function getButtonLoader(state) {
    return state.location.get('buttonLoader');
  },
  getImportLoader: function getImportLoader(state) {
    return state.location.get('importLoader');
  },
  getDialogLoader: function getDialogLoader(state) {
    return state.location.get('dialogLoader');
  },
  getDownloadLoader: function getDownloadLoader(state) {
    return state.location.get('downloadLoader');
  },
  getOOSStatusLoader: function getOOSStatusLoader(state) {
    return state.location.get('oosStatusLoader');
  }
};
export default location;