var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var types = {
  GET_RFID_INFO_LIST: 'GET_RFID_INFO_LIST',
  REQUEST_GET_RFID_INFO_LIST: 'REQUEST_GET_RFID_INFO_LIST',
  RECEIVE_GET_RFID_INFO_LIST: 'RECEIVE_GET_RFID_INFO_LIST',
  RECEIVE_GET_RFID_INFO_LIST_FAIL: 'RECEIVE_GET_RFID_INFO_LIST_FAIL'
};
export var actions = {
  getRFIDInfoList: function getRFIDInfoList(payload) {
    return {
      type: types.GET_RFID_INFO_LIST,
      payload: payload
    };
  },
  requestRFIDInfoList: function requestRFIDInfoList() {
    return {
      type: types.REQUEST_GET_RFID_INFO_LIST
    };
  },
  receiveRFIDInfoList: function receiveRFIDInfoList(rfidinfo) {
    return {
      type: types.RECEIVE_GET_RFID_INFO_LIST,
      rfidinfo: rfidinfo
    };
  },
  receiveRFIDInfoListFail: function receiveRFIDInfoListFail() {
    return {
      type: types.RECEIVE_GET_RFID_INFO_LIST_FAIL
    };
  }
};
var initialState = Immutable.fromJS({
  list: {},
  listLoader: false
});

var rfid = function rfid() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.REQUEST_GET_RFID_INFO_LIST:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_RFID_INFO_LIST:
      return state.set('list', Immutable.fromJS(action.rfidinfo)).set('listLoader', false);

    case types.RECEIVE_GET_RFID_INFO_LIST_FAIL:
      return state.set('listLoader', false);

    default:
      return state;
  }
};

export var selectors = {
  getRFIDTagInfo: function getRFIDTagInfo(state) {
    return state.rfid.get('list');
  }
};
export default rfid;