var __extensible_get__ = require("extensible-runtime").immutable;

import axios from 'axios';
export var getScac = function getScac(id) {
  return axios.get("/scacs/".concat(id));
};
export var getScacList = function getScacList(payload) {
  var encodedKeyword = payload.keyword ? encodeURIComponent(payload.keyword) : '';
  var keyword = payload.keyword ? "keyword=".concat(encodedKeyword, "&") : '';
  var skip = typeof payload.skip !== 'undefined' ? "skip=".concat(payload.skip, "&") : '';
  var take = payload.take ? "take=".concat(payload.take, "&") : '';
  var query = skip + take + keyword;
  return axios.get("/scacs".concat(query.length ? '?' : '').concat(query.slice(0, query.length - 1)));
};
export var addScac = function addScac(payload) {
  return axios.post('/scacs', payload);
};
export var updateScac = function updateScac(payload) {
  return axios.put("/scacs/".concat(payload.Scac.ID), payload);
};
export var deleteScac = function deleteScac(id) {
  return axios["delete"]("/scacs/".concat(id));
};
export var importScacs = function importScacs(payload) {
  return axios.post('/scacs/import', payload);
};
export var downloadCSVExample = function downloadCSVExample() {
  return axios.get('/scacs/importexample');
};