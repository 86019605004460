var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var initFilters = function initFilters() {
  return Immutable.fromJS({
    types: [],
    keyword: ''
  });
};
export var getLookupList = function getLookupList() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initFilters();
  return {
    types: obj.get('types').toJS(),
    keyword: obj.get('keyword')
  };
};