var __extensible_get__ = require("extensible-runtime").immutable;

import * as icons from 'mdi-material-ui';
import { Tasks, TaskData } from 'containers/Tasks';
import { Trailers, TrailerData } from 'containers/Trailers';
import { DockActivity } from 'containers/DockActivity';
import { Users } from 'containers/Users';
import { SiteMessages, FleetMessages } from 'containers/Messages';
import { Logout } from 'containers/Auth';
import { SiteSettings, InspectionList, SitesManagement } from 'containers/Sites';
import { Companies } from 'containers/Companies';
import { MapView } from 'containers/MapView';
import HelpView from 'containers/Help/HelpView';
import { DockGroupDriver } from 'containers/DockGroupDrivers';
export var securedRoutes = [{
  key: 'tasks',
  path: '/tasks',
  label: 'Tasks',
  title: 'Tasks',
  component: Tasks,
  clearance: ['admin', 'superadmin', 'user'],
  icon: icons.FormatListChecks
}, {
  key: 'yard-control',
  path: '/yard-control',
  label: 'Yard Control',
  title: 'Arrive/Depart trailers',
  component: Trailers,
  clearance: ['admin', 'superadmin', 'yardcontrol'],
  icon: icons.TruckTrailer
}, {
  key: 'dock-activity',
  path: '/dock-activity',
  label: 'Dock Activity',
  title: 'Dock Activity',
  component: DockActivity,
  clearance: ['admin', 'superadmin', 'user'],
  icon: icons.TruckDelivery
}, {
  key: 'driver-dock-group',
  path: '/driver-dock-groups',
  label: 'Driver Dock Groups',
  title: 'Driver Dock Groups',
  component: DockGroupDriver,
  clearance: ['admin', 'superadmin', 'driversupervisor'],
  icon: icons.TruckDelivery
}, {
  key: 'task-data',
  path: '/task-data',
  label: 'Task Data',
  title: 'Tasks (admin controls)',
  component: TaskData,
  clearance: ['admin', 'superadmin', 'user'],
  icon: icons.DatabaseCheck
}, {
  key: 'trailer-data',
  path: '/trailer-data',
  label: 'Yard Inventory Export',
  title: 'Yard Inventory Export',
  component: TrailerData,
  clearance: ['admin', 'superadmin', 'user', 'yardcontrol'],
  icon: icons.DatabaseExport
}, {
  key: 'site-messages',
  path: '/site-messages',
  label: 'Site Messages',
  title: 'Site Messages',
  component: SiteMessages,
  clearance: ['admin', 'superadmin'],
  icon: icons.MessageTextOutline
}, {
  key: 'fleet-messages',
  path: '/fleet-messages',
  label: 'Fleet Messages',
  title: 'Fleet Messages',
  component: FleetMessages,
  clearance: ['admin', 'superadmin'],
  icon: icons.MessageBulleted
}, {
  key: 'site-settings',
  path: '/site-settings',
  label: 'Site Settings',
  title: 'Site Settings',
  component: SiteSettings,
  clearance: 'superadmin',
  icon: icons.CogOutline
}, {
  key: 'personnel',
  path: '/personnel',
  label: 'Site Personnel',
  title: 'Site Personnel',
  component: Users,
  clearance: ['admin', 'superadmin'],
  icon: icons.AccountMultiple
}, {
  key: 'inspection-list',
  path: '/inspection-list',
  label: 'Truck Inspection List',
  title: 'Truck Inspection List',
  component: InspectionList,
  clearance: 'superadmin',
  icon: icons.TruckCheck
}, {
  key: 'sites-management',
  path: '/sites-management',
  label: 'Sites Management',
  title: 'Sites Management',
  component: SitesManagement,
  clearance: 'superadmin',
  icon: icons.MapMarkerMultiple
}, {
  key: 'companies',
  path: '/companies',
  label: 'Companies',
  title: 'Companies',
  component: Companies,
  clearance: 'superadmin',
  icon: icons.Domain
}, {
  key: 'map-view',
  path: '/map-view',
  label: 'Mapview',
  title: 'Mapview',
  component: MapView,
  clearance: ['admin', 'superadmin', 'user', 'yardcontrol'],
  icon: icons.Map
}, {
  key: 'help',
  path: '/help',
  label: 'Help/FAQ',
  title: 'Help/FAQ',
  component: HelpView,
  clearance: ['admin', 'superadmin', 'user', 'yardcontrol'],
  icon: icons.HelpCircleOutline
}];
export var profileRoutes = [{
  key: 'logout',
  path: '/logout',
  label: 'Logout',
  title: 'Logout',
  component: Logout,
  icon: icons.Logout
}];
export default [].concat(securedRoutes);