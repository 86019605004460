var __extensible_get__ = require("extensible-runtime").immutable;

import { Login } from 'containers/Auth';
var routes = [{
  key: 'login',
  path: '/login',
  label: 'Login',
  title: 'Login',
  component: Login
}];
export default routes;