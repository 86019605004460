var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var types = {
  GET_MESSAGE_LIST: 'GET_MESSAGE_LIST',
  REQUEST_GET_MESSAGE_LIST: 'REQUEST_GET_MESSAGE_LIST',
  RECEIVE_GET_MESSAGE_LIST: 'RECEIVE_GET_MESSAGE_LIST',
  RECEIVE_GET_MESSAGE_LIST_FAIL: 'RECEIVE_GET_MESSAGE_LIST_FAIL',
  ADD_MESSAGE: 'ADD_MESSAGE',
  REQUEST_ADD_MESSAGE: 'REQUEST_ADD_MESSAGE',
  RECEIVE_ADD_MESSAGE: 'RECEIVE_ADD_MESSAGE',
  RECEIVE_ADD_MESSAGE_FAIL: 'RECEIVE_ADD_MESSAGE_FAIL',
  PIN_MESSAGE: 'PIN_MESSAGE',
  REQUEST_PIN_MESSAGE: 'REQUEST_PIN_MESSAGE',
  RECEIVE_PIN_MESSAGE: 'RECEIVE_PIN_MESSAGE',
  RECEIVE_PIN_MESSAGE_FAIL: 'RECEIVE_PIN_MESSAGE_FAIL',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  REQUEST_DELETE_MESSAGE: 'REQUEST_DELETE_MESSAGE',
  RECEIVE_DELETE_MESSAGE: 'RECEIVE_DELETE_MESSAGE',
  RECEIVE_DELETE_MESSAGE_FAIL: 'RECEIVE_DELETE_MESSAGE_FAIL'
};
export var actions = {
  getMessageList: function getMessageList(payload) {
    return {
      type: types.GET_MESSAGE_LIST,
      payload: payload
    };
  },
  requestGetMessageList: function requestGetMessageList() {
    return {
      type: types.REQUEST_GET_MESSAGE_LIST
    };
  },
  receiveGetMessageList: function receiveGetMessageList(messages) {
    return {
      type: types.RECEIVE_GET_MESSAGE_LIST,
      messages: messages
    };
  },
  receiveGetMessageListFail: function receiveGetMessageListFail() {
    return {
      type: types.RECEIVE_GET_MESSAGE_LIST_FAIL
    };
  },
  addMessage: function addMessage(payload, to, callback) {
    return {
      type: types.ADD_MESSAGE,
      payload: payload,
      to: to,
      callback: callback
    };
  },
  requestAddMessage: function requestAddMessage() {
    return {
      type: types.REQUEST_ADD_MESSAGE
    };
  },
  receiveAddMessage: function receiveAddMessage() {
    var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return {
      type: types.RECEIVE_ADD_MESSAGE,
      message: message
    };
  },
  receiveAddMessageFail: function receiveAddMessageFail() {
    return {
      type: types.RECEIVE_ADD_MESSAGE_FAIL
    };
  },
  pinMessage: function pinMessage(id, callback) {
    return {
      type: types.PIN_MESSAGE,
      id: id,
      callback: callback
    };
  },
  requestPinMessage: function requestPinMessage() {
    return {
      type: types.REQUEST_PIN_MESSAGE
    };
  },
  receivePinMessage: function receivePinMessage() {
    var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return {
      type: types.RECEIVE_PIN_MESSAGE,
      message: message
    };
  },
  receivePinMessageFail: function receivePinMessageFail() {
    return {
      type: types.RECEIVE_PIN_MESSAGE_FAIL
    };
  },
  deleteMessage: function deleteMessage(id, callback) {
    return {
      type: types.DELETE_MESSAGE,
      id: id,
      callback: callback
    };
  },
  requestDeleteMessage: function requestDeleteMessage() {
    return {
      type: types.REQUEST_DELETE_MESSAGE
    };
  },
  receiveDeleteMessage: function receiveDeleteMessage(id) {
    return {
      type: types.RECEIVE_DELETE_MESSAGE,
      id: id
    };
  },
  receiveDeleteMessageFail: function receiveDeleteMessageFail() {
    return {
      type: types.RECEIVE_DELETE_MESSAGE_FAIL
    };
  }
};
var initialState = Immutable.fromJS({
  messages: [],
  listLoader: false,
  buttonLoader: false,
  dialogLoader: false
});

var receiveAddMessage = function receiveAddMessage(state, action) {
  return state.update('messages', function (messages) {
    return messages.insert(0, Immutable.fromJS(action.message));
  });
};

var receivePinMessage = function receivePinMessage(state, action) {
  var index = state.get('messages').findIndex(function (msg) {
    return msg.get('ID') === action.ID;
  });
  if (index === -1) return state;
  return state.setIn(['messages', index], Immutable.fromJS(action.message));
};

var receiveDeleteMessage = function receiveDeleteMessage(state, action) {
  var index = state.get('messages').findIndex(function (msg) {
    return msg.get('ID') === action.id;
  });
  return state.update('messages', function (messages) {
    return messages["delete"](index);
  });
};

var message = function message() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.REQUEST_GET_MESSAGE_LIST:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_MESSAGE_LIST:
      return state.set('messages', Immutable.fromJS(action.messages)).set('listLoader', false);

    case types.RECEIVE_GET_MESSAGE_LIST_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_ADD_MESSAGE:
      return state.set('buttonLoader', true);

    case types.RECEIVE_ADD_MESSAGE:
      return receiveAddMessage(state, action).set('buttonLoader', false);

    case types.RECEIVE_ADD_MESSAGE_FAIL:
      return state.set('buttonLoader', false);

    case types.REQUEST_PIN_MESSAGE:
      return state.set('listLoader', true);

    case types.RECEIVE_PIN_MESSAGE:
      return receivePinMessage(state, action).set('listLoader', false);

    case types.RECEIVE_PIN_MESSAGE_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_DELETE_MESSAGE:
      return state.set('dialogLoader', true);

    case types.RECEIVE_DELETE_MESSAGE:
      return receiveDeleteMessage(state, action).set('dialogLoader', false);

    case types.RECEIVE_DELETE_MESSAGE_FAIL:
      return state.set('dialogLoader', false);

    default:
      return state;
  }
};

export var selectors = {
  getMessages: function getMessages(state) {
    return state.message.get('messages');
  },
  getListLoader: function getListLoader(state) {
    return state.message.get('listLoader');
  },
  getButtonLoader: function getButtonLoader(state) {
    return state.message.get('buttonLoader');
  },
  getDialogLoader: function getDialogLoader(state) {
    return state.message.get('dialogLoader');
  }
};
export default message;