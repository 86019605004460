var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var types = {
  GET_ACTIVE_SESSION: 'GET_ACTIVE_SESSION',
  REQUEST_GET_ACTIVE_SESSION: 'REQUEST_GET_ACTIVE_SESSION',
  RECEIVE_GET_ACTIVE_SESSION: 'RECEIVE_GET_ACTIVE_SESSION',
  RECEIVE_GET_ACTIVE_SESSION_FAIL: 'RECEIVE_GET_ACTIVE_SESSION_FAIL',
  GET_SESSION_LIST: 'GET_SESSION_LIST',
  REQUEST_GET_SESSION_LIST: 'REQUEST_GET_SESSION_LIST',
  RECEIVE_GET_SESSION_LIST: 'RECEIVE_GET_SESSION_LIST',
  RECEIVE_GET_SESSION_LIST_FAIL: 'RECEIVE_GET_SESSION_LIST_FAIL',
  START_SESSION: 'START_SESSION',
  REQUEST_START_SESSION: 'REQUEST_START_SESSION',
  RECEIVE_START_SESSION: 'RECEIVE_START_SESSION',
  RECEIVE_START_SESSION_FAIL: 'RECEIVE_START_SESSION_FAIL',
  CLOSE_SESSION: 'CLOSE_SESSION',
  REQUEST_CLOSE_SESSION: 'REQUEST_CLOSE_SESSION',
  RECEIVE_CLOSE_SESSION: 'RECEIVE_CLOSE_SESSION',
  RECEIVE_CLOSE_SESSION_FAIL: 'RECEIVE_CLOSE_SESSION_FAIL',
  SIGN_OFF_TRAILER: 'SIGN_OFF_TRAILER',
  REQUEST_SIGN_OFF_TRAILER: 'REQUEST_SIGN_OFF_TRAILER',
  RECEIVE_SIGN_OFF_TRAILER: 'RECEIVE_SIGN_OFF_TRAILER',
  RECEIVE_SIGN_OFF_TRAILER_FAIL: 'RECEIVE_SIGN_OFF_TRAILER_FAIL'
};
export var actions = {
  getActiveSession: function getActiveSession(sessionType, callback) {
    return {
      type: types.GET_ACTIVE_SESSION,
      sessionType: sessionType,
      callback: callback
    };
  },
  requestGetActiveSession: function requestGetActiveSession() {
    return {
      type: types.REQUEST_GET_ACTIVE_SESSION
    };
  },
  receiveGetActiveSession: function receiveGetActiveSession(session) {
    return {
      type: types.RECEIVE_GET_ACTIVE_SESSION,
      session: session
    };
  },
  receiveGetActiveSessionFail: function receiveGetActiveSessionFail() {
    return {
      type: types.RECEIVE_GET_ACTIVE_SESSION_FAIL
    };
  },
  getSessionList: function getSessionList() {
    return {
      type: types.GET_SESSION_LIST
    };
  },
  requestGetSessionList: function requestGetSessionList() {
    return {
      type: types.REQUEST_GET_SESSION_LIST
    };
  },
  receiveGetSessionList: function receiveGetSessionList(sessions) {
    return {
      type: types.RECEIVE_GET_SESSION_LIST,
      sessions: sessions
    };
  },
  receiveGetSessionListFail: function receiveGetSessionListFail() {
    return {
      type: types.RECEIVE_GET_SESSION_LIST_FAIL
    };
  },
  startSession: function startSession(sessionType, callback) {
    return {
      type: types.START_SESSION,
      sessionType: sessionType,
      callback: callback
    };
  },
  requestStartSession: function requestStartSession() {
    return {
      type: types.REQUEST_START_SESSION
    };
  },
  receiveStartSession: function receiveStartSession(session) {
    return {
      type: types.RECEIVE_START_SESSION,
      session: session
    };
  },
  receiveStartSessionFail: function receiveStartSessionFail() {
    return {
      type: types.RECEIVE_START_SESSION_FAIL
    };
  },
  closeSession: function closeSession(id, callback) {
    return {
      type: types.CLOSE_SESSION,
      id: id,
      callback: callback
    };
  },
  requestCloseSession: function requestCloseSession() {
    return {
      type: types.REQUEST_CLOSE_SESSION
    };
  },
  receiveCloseSession: function receiveCloseSession() {
    return {
      type: types.RECEIVE_CLOSE_SESSION
    };
  },
  receiveCloseSessionFail: function receiveCloseSessionFail() {
    return {
      type: types.RECEIVE_CLOSE_SESSION_FAIL
    };
  },
  signOffTrailer: function signOffTrailer(id, status, boxTemp, fuelLevel, defrost, callback) {
    return {
      type: types.SIGN_OFF_TRAILER,
      id: id,
      status: status,
      boxTemp: boxTemp,
      fuelLevel: fuelLevel,
      defrost: defrost,
      callback: callback
    };
  },
  requestSignOffTrailer: function requestSignOffTrailer(id) {
    return {
      type: types.REQUEST_SIGN_OFF_TRAILER,
      id: id
    };
  },
  receiveSignOffTrailer: function receiveSignOffTrailer(session) {
    return {
      type: types.RECEIVE_SIGN_OFF_TRAILER,
      session: session
    };
  },
  receiveSignOffTrailerFail: function receiveSignOffTrailerFail() {
    return {
      type: types.RECEIVE_SIGN_OFF_TRAILER_FAIL
    };
  }
};
var initialState = Immutable.fromJS({
  sessions: [],
  activeSession: {
    ID: 0,
    Type: 0
  },
  auditedId: 0,
  currentLoader: false,
  listLoader: false,
  buttonLoader: false,
  dialogLoader: false
});

var receiveGetSessionList = function receiveGetSessionList(state, action) {
  return state.set('list', Immutable.fromJS(action.sessions));
};

var receiveActiveSession = function receiveActiveSession(state, action) {
  return state.update('activeSession', // if session was closed in the meantime, action.session will be null
  function (session) {
    return action.session ? Immutable.fromJS(action.session) : Immutable.fromJS({
      ID: 0,
      Type: 0
    });
  });
};

var receiveCloseSession = function receiveCloseSession(state, action) {
  return state.set('activeSession', Immutable.fromJS({
    ID: 0,
    Type: 0
  }));
};

var audit = function audit() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.REQUEST_GET_ACTIVE_SESSION:
      return state.set('currentLoader', true);

    case types.RECEIVE_GET_ACTIVE_SESSION:
      return receiveActiveSession(state, action).set('currentLoader', false);

    case types.RECEIVE_GET_ACTIVE_SESSION_FAIL:
      return state.set('currentLoader', false);

    case types.REQUEST_GET_SESSION_LIST:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_SESSION_LIST:
      return receiveGetSessionList(state, action).set('listLoader', false);

    case types.RECEIVE_GET_SESSION_LIST_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_START_SESSION:
      return state.set('dialogLoader', true);

    case types.RECEIVE_START_SESSION:
      return receiveActiveSession(state, action).set('dialogLoader', false);

    case types.RECEIVE_START_SESSION_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_CLOSE_SESSION:
      return state.set('dialogLoader', true);

    case types.RECEIVE_CLOSE_SESSION:
      return receiveCloseSession(state, action).set('dialogLoader', false);

    case types.RECEIVE_CLOSE_SESSION_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_SIGN_OFF_TRAILER:
      return state.set('buttonLoader', true).set('auditedId', action.id);

    case types.RECEIVE_SIGN_OFF_TRAILER:
      return state.set('buttonLoader', false).set('auditedId', 0);

    case types.RECEIVE_SIGN_OFF_TRAILER_FAIL:
      return state.set('buttonLoader', false).set('auditedId', 0);

    default:
      return state;
  }
};

export var selectors = {
  getSessions: function getSessions(state) {
    return state.audit.get('list');
  },
  getActiveSession: function getActiveSession(state) {
    return state.audit.get('activeSession');
  },
  getCurrentLoader: function getCurrentLoader(state) {
    return state.audit.get('currentLoader');
  },
  getListLoader: function getListLoader(state) {
    return state.audit.get('listLoader');
  },
  getButtonLoader: function getButtonLoader(state) {
    return state.audit.get('buttonLoader');
  },
  getDialogLoader: function getDialogLoader(state) {
    return state.audit.get('dialogLoader');
  },
  getAuditedId: function getAuditedId(state) {
    return state.audit.get('auditedId');
  }
};
export default audit;