var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var initPayload = function initPayload() {
  return Immutable.fromJS({
    SiteID: 0,
    Name: "",
    Type: 0,
    Description: "",
    Address1: "",
    Address2: "",
    City: '',
    State: "",
    Zip: "",
    Latitude1: 0,
    Longitude1: 0,
    Latitude2: 0,
    Longitude2: 0,
    Latitude3: 0,
    Longitude3: 0,
    Latitude4: 0,
    Longitude4: 0,
    ReferenceLatitude: 0,
    ReferenceLongitude: 0
  });
};
export var locationPayload = function locationPayload() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initPayload();
  return {
    Location: {
      SiteID: obj.get("SiteID"),
      Name: obj.get("Name"),
      Type: obj.get("Type"),
      Description: obj.get("Description"),
      Address1: obj.get("Address1"),
      Address2: obj.get("Address2"),
      ID: obj.get("ID"),
      City: obj.get("City"),
      State: obj.get("State"),
      Zip: obj.get("Zip"),
      Latitude1: obj.get("Latitude1"),
      Longitude1: obj.get("Longitude1"),
      Latitude2: obj.get("Latitude2"),
      Longitude2: obj.get("Longitude2"),
      Latitude3: obj.get("Latitude3"),
      Longitude3: obj.get("Longitude3"),
      Latitude4: obj.get("Latitude4"),
      Longitude4: obj.get("Longitude4"),
      ReferenceLatitude: obj.get("ReferenceLatitude"),
      ReferenceLongitude: obj.get("ReferenceLongitude")
    }
  };
};
export var importLocations = function importLocations(obj) {
  return {
    Locations: obj.map(function (item) {
      return {
        Type: item.get('Type'),
        Name: item.get('Name')
      };
    }).toJS()
  };
};
export var updateOOSStatusPayload = function updateOOSStatusPayload(id, oos) {
  return {
    DoorID: id,
    DoorStatus: oos
  };
};