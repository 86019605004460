var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var types = {
  GET_CHECKLIST: 'GET_CHECKLIST',
  REQUEST_GET_CHECKLIST: 'REQUEST_GET_CHECKLIST',
  RECEIVE_GET_CHECKLIST: 'RECEIVE_GET_CHECKLIST',
  RECEIVE_GET_CHECKLIST_FAIL: 'RECEIVE_GET_CHECKLIST_FAIL',
  ADD_LIST_ITEM: 'ADD_LIST_ITEM',
  REQUEST_ADD_LIST_ITEM: 'REQUEST_ADD_LIST_ITEM',
  RECEIVE_ADD_LIST_ITEM: 'RECEIVE_ADD_LIST_ITEM',
  RECEIVE_ADD_LIST_ITEM_FAIL: 'RECEIVE_ADD_LIST_ITEM_FAIL',
  SET_LIST_ITEM: 'SET_LIST_ITEM',
  REQUEST_SET_LIST_ITEM: 'REQUEST_SET_LIST_ITEM',
  RECEIVE_SET_LIST_ITEM: 'RECEIVE_SET_LIST_ITEM',
  RECEIVE_SET_LIST_ITEM_FAIL: 'RECEIVE_SET_LIST_ITEM_FAIL',
  DELETE_LIST_ITEM: 'DELETE_LIST_ITEM',
  REQUEST_DELETE_LIST_ITEM: 'REQUEST_DELETE_LIST_ITEM',
  RECEIVE_DELETE_LIST_ITEM: 'RECEIVE_DELETE_LIST_ITEM',
  RECEIVE_DELETE_LIST_ITEM_FAIL: 'RECEIVE_DELETE_LIST_ITEM_FAIL'
};
export var actions = {
  getChecklist: function getChecklist() {
    return {
      type: types.GET_CHECKLIST
    };
  },
  requestGetChecklist: function requestGetChecklist() {
    return {
      type: types.REQUEST_GET_CHECKLIST
    };
  },
  receiveGetChecklist: function receiveGetChecklist(list) {
    return {
      type: types.RECEIVE_GET_CHECKLIST,
      list: list
    };
  },
  receiveGetChecklistFail: function receiveGetChecklistFail() {
    return {
      type: types.RECEIVE_GET_CHECKLIST_FAIL
    };
  },
  addListItem: function addListItem(payload, callback) {
    return {
      type: types.ADD_LIST_ITEM,
      payload: payload,
      callback: callback
    };
  },
  requestAddListItem: function requestAddListItem() {
    return {
      type: types.REQUEST_ADD_LIST_ITEM
    };
  },
  receiveAddListItem: function receiveAddListItem(item) {
    return {
      type: types.RECEIVE_ADD_LIST_ITEM,
      item: item
    };
  },
  receiveAddListItemFail: function receiveAddListItemFail() {
    return {
      type: types.RECEIVE_ADD_LIST_ITEM_FAIL
    };
  },
  setListItem: function setListItem(payload) {
    return {
      type: types.SET_LIST_ITEM,
      payload: payload
    };
  },
  requestSetListItem: function requestSetListItem() {
    return {
      type: types.REQUEST_SET_LIST_ITEM
    };
  },
  receiveSetListItem: function receiveSetListItem(item) {
    return {
      type: types.RECEIVE_SET_LIST_ITEM,
      item: item
    };
  },
  receiveSetListItemFail: function receiveSetListItemFail() {
    return {
      type: types.RECEIVE_SET_LIST_ITEM_FAIL
    };
  },
  deleteListItem: function deleteListItem(id, callback) {
    return {
      type: types.DELETE_LIST_ITEM,
      id: id,
      callback: callback
    };
  },
  requestDeleteListItem: function requestDeleteListItem() {
    return {
      type: types.REQUEST_DELETE_LIST_ITEM
    };
  },
  receiveDeleteListItem: function receiveDeleteListItem(id) {
    return {
      type: types.RECEIVE_DELETE_LIST_ITEM,
      id: id
    };
  },
  receiveDeleteListItemFail: function receiveDeleteListItemFail() {
    return {
      type: types.RECEIVE_DELETE_LIST_ITEM_FAIL
    };
  }
};
var initialState = Immutable.fromJS({
  inspectionList: [],
  listLoader: false,
  buttonLoader: false,
  dialogLoader: false
});

var receiveGetChecklist = function receiveGetChecklist(state, action) {
  return state.set('inspectionList', Immutable.fromJS(action.list));
};

var receiveAddListItem = function receiveAddListItem(state, action) {
  return state.update('inspectionList', function (list) {
    return list.push(Immutable.fromJS(action.item));
  });
};

var receiveSetListItem = function receiveSetListItem(state, action) {
  var index = state.get('inspectionList').findIndex(function (item) {
    return item.get('ID') === action.item.ID;
  });
  return state.setIn(['inspectionList', index], Immutable.fromJS(action.item));
};

var receiveDeleteListItem = function receiveDeleteListItem(state, action) {
  var index = state.get('inspectionList').findIndex(function (item) {
    return item.get('ID') === action.id;
  });
  return state.update('inspectionList', function (list) {
    return list["delete"](index);
  });
};

var checklist = function checklist() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.REQUEST_GET_CHECKLIST:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_CHECKLIST:
      return receiveGetChecklist(state, action).set('listLoader', false);

    case types.RECEIVE_GET_CHECKLIST_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_ADD_LIST_ITEM:
      return state.set('buttonLoader', true);

    case types.RECEIVE_ADD_LIST_ITEM:
      return receiveAddListItem(state, action).set('buttonLoader', false);

    case types.RECEIVE_ADD_LIST_ITEM_FAIL:
      return state.set('buttonLoader', false);

    case types.REQUEST_SET_LIST_ITEM:
      return state.set('dialogLoader', true);

    case types.RECEIVE_SET_LIST_ITEM:
      return receiveSetListItem(state, action).set('dialogLoader', false);

    case types.RECEIVE_SET_LIST_ITEM_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_DELETE_LIST_ITEM:
      return state.set('dialogLoader', true);

    case types.RECEIVE_DELETE_LIST_ITEM:
      return receiveDeleteListItem(state, action).set('dialogLoader', false);

    case types.RECEIVE_DELETE_LIST_ITEM_FAIL:
      return state.set('dialogLoader', false);

    default:
      return state;
  }
};

export var selectors = {
  getChecklist: function getChecklist(state) {
    return state.checklist.get('inspectionList');
  },
  getListLoader: function getListLoader(state) {
    return state.checklist.get('listLoader');
  },
  getButtonLoader: function getButtonLoader(state) {
    return state.checklist.get('buttonLoader');
  },
  getDialogLoader: function getDialogLoader(state) {
    return state.checklist.get('dialogLoader');
  }
};
export default checklist;