var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var types = {
  GET_TRAILER: 'GET_TRAILER',
  REQUEST_GET_TRAILER: 'REQUEST_GET_TRAILER',
  RECEIVE_GET_TRAILER: 'RECEIVE_GET_TRAILER',
  RECEIVE_GET_TRAILER_FAIL: 'RECEIVE_GET_TRAILER_FAIL',
  UPDATE_TRAILER_STATE: 'UPDATE_TRAILER_STATE',
  REQUEST_UPDATE_TRAILER_STATE: 'REQUEST_UPDATE_TRAILER_STATE',
  RECEIVE_UPDATE_TRAILER_STATE: 'RECEIVE_UPDATE_TRAILER_STATE',
  RECEIVE_UPDATE_TRAILER_STATE_FAIL: 'RECEIVE_UPDATE_TRAILER_STATE_FAIL',
  GET_TRAILER_LIST: 'GET_TRAILER_LIST',
  REQUEST_GET_TRAILER_LIST: 'REQUEST_GET_TRAILER_LIST',
  RECEIVE_GET_TRAILER_LIST: 'RECEIVE_GET_TRAILER_LIST',
  RECEIVE_GET_TRAILER_LIST_FAIL: 'RECEIVE_GET_TRAILER_LIST_FAIL',
  ADD_TRAILER: 'ADD_TRAILER',
  REQUEST_ADD_TRAILER: 'REQUEST_ADD_TRAILER',
  RECEIVE_ADD_TRAILER: 'RECEIVE_ADD_TRAILER',
  RECEIVE_ADD_TRAILER_FAIL: 'RECEIVE_ADD_TRAILER_FAIL',
  ADD_TRAILER_TO_SESSION: 'ADD_TRAILER_TO_SESSION',
  REQUEST_ADD_TRAILER_TO_SESSION: 'REQUEST_ADD_TRAILER_TO_SESSION',
  RECEIVE_ADD_TRAILER_TO_SESSION: 'RECEIVE_ADD_TRAILER_TO_SESSION',
  RECEIVE_ADD_TRAILER_TO_SESSION_FAIL: 'RECEIVE_ADD_TRAILER_TO_SESSION_FAIL',
  SEND_TRAILER_ALERT: 'SEND_TRAILER_ALERT',
  REQUEST_SEND_TRAILER_ALERT: 'REQUEST_SEND_TRAILER_ALERT',
  RECEIVE_SEND_TRAILER_ALERT: 'RECEIVE_SEND_TRAILER_ALERT',
  RECEIVE_SEND_TRAILER_ALERT_FAIL: 'RECEIVE_SEND_TRAILER_ALERT_FAIL',
  UPDATE_TRAILER: 'UPDATE_TRAILER',
  REQUEST_UPDATE_TRAILER: 'REQUEST_UPDATE_TRAILER',
  RECEIVE_UPDATE_TRAILER: 'RECEIVE_UPDATE_TRAILER',
  RECEIVE_UPDATE_TRAILER_FAIL: 'RECEIVE_UPDATE_TRAILER_FAIL',
  DELETE_TRAILER: 'DELETE_TRAILER',
  REQUEST_DELETE_TRAILER: 'REQUEST_DELETE_TRAILER',
  RECEIVE_DELETE_TRAILER: 'RECEIVE_DELETE_TRAILER',
  RECEIVE_DELETE_TRAILER_FAIL: 'RECEIVE_DELETE_TRAILER_FAIL',
  EXPORT_YARD_INVENTORY: 'EXPORT_YARD_INVENTORY',
  REQUEST_EXPORT_YARD_INVENTORY: 'REQUEST_EXPORT_YARD_INVENTORY',
  RECEIVE_EXPORT_YARD_INVENTORY: 'RECEIVE_EXPORT_YARD_INVENTORY',
  RECEIVE_EXPORT_YARD_INVENTORY_FAIL: 'RECEIVE_EXPORT_YARD_INVENTORY_FAIL',
  GET_TRAILER_HISTORY: 'GET_TRAILER_HISTORY',
  REQUEST_GET_TRAILER_HISTORY: 'REQUEST_GET_TRAILER_HISTORY',
  RECEIVE_GET_TRAILER_HISTORY: 'RECEIVE_GET_TRAILER_HISTORY',
  RECEIVE_GET_TRAILER_HISTORY_FAIL: 'RECEIVE_GET_TRAILER_HISTORY_FAIL',
  GET_PHOTECTOR_EVENTS: 'GET_PHOTECTOR_EVENTS',
  REQUEST_GET_PHOTECTOR_EVENTS: 'REQUEST_GET_PHOTECTOR_EVENTS',
  RECEIVE_GET_PHOTECTOR_EVENTS: 'RECEIVE_GET_PHOTECTOR_EVENTS',
  RECEIVE_GET_PHOTECTOR_EVENTS_FAIL: 'RECEIVE_GET_PHOTECTOR_EVENTS_FAIL',
  RECEIVE_TRAILER_SESSION_DATA: 'RECEIVE_TRAILER_SESSION_DATA',

  /**types related to fetching trailer inof based on reference id */
  GET_TRAILER_LIST_INFO: 'GET_TRAILER_LIST_INFO',
  REQUEST_GET_TRAILER_LIST_INFO: 'REQUEST_GET_TRAILER_LIST_INFO',
  RECEIVE_GET_TRAILER_LIST_INFO: 'RECEIVE_GET_TRAILER_LIST_INFO',
  RECEIVE_GET_TRAILER_LIST_FAIL_INFO: 'RECEIVE_GET_TRAILER_LIST_FAIL_INFO',
  UPDATE_TRAILER_LIST_INFO: 'UPDATE_TRAILER_LIST_INFO',
  SET_TRAILER_LIST_INFO_EMPTY: 'SET_TRAILER_LIST_INFO_EMPTY',

  /**types related to fetching trailer info based on trailer number */
  GET_TRAILER_INFO_BASED_ON_NUMBER: 'GET_TRAILER_INFO_BASED_ON_NUMBER',
  REQUEST_GET_TRAILER_INFO_BASED_ON_NUMBER: 'REQUEST_GET_TRAILER_INFO_BASED_ON_NUMBER',
  RECEIVE_GET_TRAILER_INFO_BASED_ON_NUMBER: 'RECEIVE_GET_TRAILER_INFO_BASED_ON_NUMBER',
  RECEIVE_GET_TRAILER_FAIL_INFO_BASED_ON_NUMBER: 'RECEIVE_GET_TRAILER_FAIL_INFO_BASED_ON_NUMBER',
  SET_TRAILER_LIST_INFO_BASED_ON_NUMBER_EMPTY: 'SET_TRAILER_LIST_INFO_BASED_ON_NUMBER_EMPTY'
};
export var actions = {
  getTrailer: function getTrailer(id, callback) {
    return {
      type: types.GET_TRAILER,
      id: id,
      callback: callback
    };
  },
  requestGetTrailer: function requestGetTrailer() {
    return {
      type: types.REQUEST_GET_TRAILER
    };
  },
  receiveGetTrailer: function receiveGetTrailer(trailer) {
    return {
      type: types.RECEIVE_GET_TRAILER,
      trailer: trailer
    };
  },
  receiveGetTrailerFail: function receiveGetTrailerFail() {
    return {
      type: types.RECEIVE_GET_TRAILER_FAIL
    };
  },
  addTrailer: function addTrailer(payload, filters, callback) {
    return {
      type: types.ADD_TRAILER,
      payload: payload,
      filters: filters,
      callback: callback
    };
  },
  requestAddTrailer: function requestAddTrailer() {
    return {
      type: types.REQUEST_ADD_TRAILER
    };
  },
  receiveAddTrailer: function receiveAddTrailer(trailer) {
    return {
      type: types.RECEIVE_ADD_TRAILER,
      trailer: trailer
    };
  },
  receiveAddTrailerFail: function receiveAddTrailerFail() {
    return {
      type: types.RECEIVE_ADD_TRAILER_FAIL
    };
  },
  addTrailerToSession: function addTrailerToSession(id, callback) {
    return {
      type: types.ADD_TRAILER_TO_SESSION,
      id: id,
      callback: callback
    };
  },
  requestAddTrailerToSession: function requestAddTrailerToSession() {
    return {
      type: types.REQUEST_ADD_TRAILER_TO_SESSION
    };
  },
  receiveAddTrailerToSession: function receiveAddTrailerToSession(trailer) {
    return {
      type: types.RECEIVE_ADD_TRAILER_TO_SESSION,
      trailer: trailer
    };
  },
  receiveAddTrailerToSessionFail: function receiveAddTrailerToSessionFail() {
    return {
      type: types.RECEIVE_ADD_TRAILER_TO_SESSION_FAIL
    };
  },
  sendTrailerAlert: function sendTrailerAlert(id, callback) {
    return {
      type: types.SEND_TRAILER_ALERT,
      id: id,
      callback: callback
    };
  },
  requestSendTrailerAlert: function requestSendTrailerAlert(id) {
    return {
      type: types.REQUEST_SEND_TRAILER_ALERT,
      id: id
    };
  },
  receiveSendTrailerAlert: function receiveSendTrailerAlert() {
    return {
      type: types.RECEIVE_SEND_TRAILER_ALERT
    };
  },
  receiveSendTrailerAlertFail: function receiveSendTrailerAlertFail() {
    return {
      type: types.RECEIVE_SEND_TRAILER_ALERT_FAIL
    };
  },
  updateTrailer: function updateTrailer(payload, filters, callback) {
    return {
      type: types.UPDATE_TRAILER,
      payload: payload,
      filters: filters,
      callback: callback
    };
  },
  requestUpdateTrailer: function requestUpdateTrailer() {
    return {
      type: types.REQUEST_UPDATE_TRAILER
    };
  },
  receiveUpdateTrailer: function receiveUpdateTrailer(trailer) {
    return {
      type: types.RECEIVE_UPDATE_TRAILER,
      trailer: trailer
    };
  },
  receiveUpdateTrailerFail: function receiveUpdateTrailerFail() {
    return {
      type: types.RECEIVE_UPDATE_TRAILER_FAIL
    };
  },
  updateTrailerState: function updateTrailerState(payload, callback) {
    return {
      type: types.UPDATE_TRAILER_STATE,
      payload: payload,
      callback: callback
    };
  },
  requestUpdateTrailerState: function requestUpdateTrailerState(id) {
    return {
      type: types.REQUEST_UPDATE_TRAILER_STATE,
      id: id
    };
  },
  receiveUpdateTrailerState: function receiveUpdateTrailerState(id) {
    return {
      type: types.RECEIVE_UPDATE_TRAILER_STATE,
      id: id
    };
  },
  receiveUpdateTrailerStateFail: function receiveUpdateTrailerStateFail() {
    return {
      type: types.RECEIVE_UPDATE_TRAILER_STATE_FAIL
    };
  },
  deleteTrailer: function deleteTrailer(id, callback) {
    return {
      type: types.DELETE_TRAILER,
      id: id,
      callback: callback
    };
  },
  requestDeleteTrailer: function requestDeleteTrailer() {
    return {
      type: types.REQUEST_DELETE_TRAILER
    };
  },
  receiveDeleteTrailer: function receiveDeleteTrailer(id) {
    return {
      type: types.RECEIVE_DELETE_TRAILER,
      id: id
    };
  },
  receiveDeleteTrailerFail: function receiveDeleteTrailerFail() {
    return {
      type: types.RECEIVE_DELETE_TRAILER_FAIL
    };
  },
  getTrailerList: function getTrailerList(payload, requestedAfterTrailerDeparted) {
    return {
      type: types.GET_TRAILER_LIST,
      payload: payload,
      requestedAfterTrailerDeparted: requestedAfterTrailerDeparted
    };
  },
  requestGetTrailerList: function requestGetTrailerList() {
    return {
      type: types.REQUEST_GET_TRAILER_LIST
    };
  },
  receiveGetTrailerList: function receiveGetTrailerList(trailers, itemsCount) {
    return {
      type: types.RECEIVE_GET_TRAILER_LIST,
      trailers: trailers,
      itemsCount: itemsCount
    };
  },
  receiveGetTrailerListFail: function receiveGetTrailerListFail() {
    return {
      type: types.RECEIVE_GET_TRAILER_LIST_FAIL
    };
  },
  receiveTrailerSessionData: function receiveTrailerSessionData(response) {
    return {
      type: types.RECEIVE_TRAILER_SESSION_DATA,
      response: response
    };
  },
  exportReport: function exportReport(payload, callback) {
    return {
      type: types.EXPORT_YARD_INVENTORY,
      payload: payload,
      callback: callback
    };
  },
  requestExportReport: function requestExportReport() {
    return {
      type: types.REQUEST_EXPORT_YARD_INVENTORY
    };
  },
  receiveExportReport: function receiveExportReport() {
    return {
      type: types.RECEIVE_EXPORT_YARD_INVENTORY
    };
  },
  receiveExportReportFail: function receiveExportReportFail() {
    return {
      type: types.RECEIVE_EXPORT_YARD_INVENTORY_FAIL
    };
  },
  getTaskHistory: function getTaskHistory(payload) {
    return {
      type: types.GET_TASK_HISTORY,
      payload: payload
    };
  },
  requestGetTaskHistory: function requestGetTaskHistory() {
    return {
      type: types.REQUEST_GET_TASK_HISTORY
    };
  },
  recieveGetTaskHistory: function recieveGetTaskHistory(taskHistory) {
    return {
      type: types.RECIEVE_GET_TASK_HISTORY,
      taskHistory: taskHistory
    };
  },
  recieveGetTaskHistoryFail: function recieveGetTaskHistoryFail() {
    return {
      type: types.RECEIVE_GET_TASK_HISTORY_FAIL
    };
  },
  getTrailerHistory: function getTrailerHistory(payload) {
    return {
      type: types.GET_TRAILER_HISTORY,
      payload: payload
    };
  },
  requestGetTrailerHistory: function requestGetTrailerHistory() {
    return {
      type: types.REQUEST_GET_TRAILER_HISTORY
    };
  },
  receiveGetTrailerHistory: function receiveGetTrailerHistory(trailerHistory) {
    return {
      type: types.RECEIVE_GET_TRAILER_HISTORY,
      trailerHistory: trailerHistory
    };
  },
  receiveGetTrailerHistoryFail: function receiveGetTrailerHistoryFail() {
    return {
      type: types.RECEIVE_GET_TRAILER_HISTORY_FAIL
    };
  },
  getPhotectorEvents: function getPhotectorEvents(payload) {
    return {
      type: types.GET_PHOTECTOR_EVENTS,
      payload: payload
    };
  },
  requestGetPhotectorEvents: function requestGetPhotectorEvents() {
    return {
      type: types.REQUEST_GET_PHOTECTOR_EVENTS
    };
  },
  receiveGetPhotectorEvents: function receiveGetPhotectorEvents(events) {
    return {
      type: types.RECEIVE_GET_PHOTECTOR_EVENTS,
      events: events
    };
  },
  receiveGetPhotectorEventsFail: function receiveGetPhotectorEventsFail() {
    return {
      type: types.RECEIVE_GET_PHOTECTOR_EVENTS_FAIL
    };
  },

  /**trailer info based on reference id */
  getTrailerInfo: function getTrailerInfo(payload) {
    return {
      type: types.GET_TRAILER_LIST_INFO,
      payload: payload
    };
  },
  requestGetTrailerListInfo: function requestGetTrailerListInfo() {
    return {
      type: types.REQUEST_GET_TRAILER_LIST_INFO
    };
  },
  receiveGetTrailerListInfo: function receiveGetTrailerListInfo(trailer) {
    return {
      type: types.RECEIVE_GET_TRAILER_LIST_INFO,
      trailer: trailer
    };
  },
  receiveGetTrailerListInfoFail: function receiveGetTrailerListInfoFail() {
    return {
      type: types.RECEIVE_GET_TRAILER_LIST_FAIL_INFO
    };
  },
  updateTrailerInfo: function updateTrailerInfo() {
    return {
      type: types.UPDATE_TRAILER_LIST_INFO
    };
  },
  updateTrailerReferenceInfo: function updateTrailerReferenceInfo() {
    return {
      type: types.SET_TRAILER_LIST_INFO_EMPTY
    };
  },

  /** trailer info based on trailer number */
  getTrailerInfoBasedOnNumber: function getTrailerInfoBasedOnNumber(payload) {
    return {
      type: types.GET_TRAILER_INFO_BASED_ON_NUMBER,
      payload: payload
    };
  },
  requestGetTrailerInfoBasedOnNumber: function requestGetTrailerInfoBasedOnNumber() {
    return {
      type: types.REQUEST_GET_TRAILER_INFO_BASED_ON_NUMBER
    };
  },
  receiveGetTrailerInfoBasedOnNumber: function receiveGetTrailerInfoBasedOnNumber(trailer, trailerCount, trailerList) {
    return {
      type: types.RECEIVE_GET_TRAILER_INFO_BASED_ON_NUMBER,
      trailer: trailer,
      trailerCount: trailerCount,
      trailerList: trailerList
    };
  },
  receiveGetTrailerInfoBasedOnNumberFail: function receiveGetTrailerInfoBasedOnNumberFail() {
    return {
      type: types.RECEIVE_GET_TRAILER_FAIL_INFO_BASED_ON_NUMBER
    };
  },
  updateTrailerInfoBasedOnNumber: function updateTrailerInfoBasedOnNumber() {
    return {
      type: types.SET_TRAILER_LIST_INFO_BASED_ON_NUMBER_EMPTY
    };
  }
};
var initialState = Immutable.fromJS({
  list: [],
  trailerHistory: [],
  listLoader: false,
  buttonLoader: false,
  dialogLoader: false,
  downloadLoader: false,
  alertLoader: false,
  trailerHistoryLoader: false,
  photectorLoader: false,
  photectorEvents: [],
  updatingId: 0,
  itemsCount: 0,
  alertedId: 0,
  trailerSearchCountBasedOnNumber: 0,
  trailersListBasedOnNumber: []
});

var receiveGetTrailerList = function receiveGetTrailerList(state, action) {
  return state.set('list', Immutable.fromJS(action.trailers)).set('itemsCount', action.itemsCount);
};

var receiveAddTrailer = function receiveAddTrailer(state, action) {
  if (!action.trailer) return state; // trailer might have been added to another site

  return state.update('list', function (list) {
    return list.push(Immutable.fromJS(action.trailer));
  }).update('itemsCount', function (value) {
    return value + 1;
  });
};

var receiveAddTrailerToSession = function receiveAddTrailerToSession(state, action) {
  // do not add a trailer to the list twice (the current list from the store - one page)
  if (!state.get('list').find(function (x) {
    return x.get('ID') === action.trailer.ID;
  })) {
    return receiveAddTrailer(state, action);
  }

  return state;
};

var receiveUpdateTrailerState = function receiveUpdateTrailerState(state, action) {
  var index = state.get('list').findIndex(function (trailer) {
    return trailer.get('ID') === action.id;
  });
  return state.updateIn(['list', index], function (trailer) {
    return trailer.update('State', function (state) {
      return state === 1 ? 2 : 1;
    }).update('StateName', function (stateName) {
      return stateName === 'Arrived' ? 'Departed' : 'Arrived';
    });
  });
};

var receiveUpdateTrailer = function receiveUpdateTrailer(state, action) {
  var index = state.get('list').findIndex(function (trailer) {
    return trailer.get('ID') == action.trailer.ID;
  });
  return state.setIn(['list', index], Immutable.fromJS(action.trailer));
};

var receiveDeleteTrailer = function receiveDeleteTrailer(state, action) {
  var index = state.get('list').findIndex(function (trailer) {
    return trailer.get('ID') === action.id;
  });
  return state.update('list', function (list) {
    return list["delete"](index);
  }).update('itemsCount', function (value) {
    return value - 1;
  });
};

var receiveGetTrailerHistory = function receiveGetTrailerHistory(state, action) {
  return state.set('trailerHistory', action.trailerHistory);
};

var receiveGetPhotectorEvents = function receiveGetPhotectorEvents(state, action) {
  state.set('photectorEvents', []);
  return state.set('photectorEvents', action.events);
};

var receiveTrailerSessionData = function receiveTrailerSessionData(state, action) {
  var index = state.get('list').findIndex(function (trailer) {
    return trailer.get('ID') === action.response.TrailerID;
  });
  return state.setIn(['list', index, 'AuditState'], action.response.TrailerAuditState).setIn(['list', index, 'BoxTemperature'], action.response.BoxTemperature).setIn(['list', index, 'ReeferFuelLevel'], action.response.ReeferFuelLevel).setIn(['list', index, 'ReeferFuelLevelName'], action.response.ReeferFuelLevelName);
};

var receiveTrailerInfoBasedOnReference = function receiveTrailerInfoBasedOnReference(state, action) {
  return state.set('referenceTrailer', Immutable.fromJS(action.trailer));
};

var trailer = function trailer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.REQUEST_GET_TRAILER:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_TRAILER:
      return state.set('currentTrailer', Immutable.fromJS(action.trailer || {})) // avoid setting null
      .set('editedTrailer', Immutable.fromJS(action.trailer)).set('listLoader', false);

    case types.RECEIVE_GET_TRAILER_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_GET_TRAILER_LIST:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_TRAILER_LIST:
      return receiveGetTrailerList(state, action).set('listLoader', false);

    case types.RECEIVE_GET_TRAILER_LIST_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_ADD_TRAILER:
      return state.set('buttonLoader', true);

    case types.RECEIVE_ADD_TRAILER:
      return receiveAddTrailer(state, action).set('buttonLoader', false);

    case types.RECEIVE_ADD_TRAILER_FAIL:
      return state.set('buttonLoader', false);

    case types.REQUEST_ADD_TRAILER_TO_SESSION:
      return state.set('buttonLoader', true);

    case types.RECEIVE_ADD_TRAILER_TO_SESSION:
      return receiveAddTrailerToSession(state, action).set('buttonLoader', false);

    case types.RECEIVE_ADD_TRAILER_TO_SESSION_FAIL:
      return state.set('buttonLoader', false);

    case types.REQUEST_SEND_TRAILER_ALERT:
      return state.set('alertLoader', true).set('alertedId', action.id);

    case types.RECEIVE_SEND_TRAILER_ALERT:
      return state.set('alertLoader', false).set('alertedId', 0);

    case types.RECEIVE_SEND_TRAILER_ALERT_FAIL:
      return state.set('alertLoader', false).set('alertedId', 0);

    case types.REQUEST_UPDATE_TRAILER_STATE:
      return state.set('dialogLoader', true).set('updatingId', action.id);

    case types.RECEIVE_UPDATE_TRAILER_STATE:
      return receiveUpdateTrailerState(state, action).set('dialogLoader', false).set('updatingId', 0);

    case types.RECEIVE_UPDATE_TRAILER_STATE_FAIL:
      return state.set('dialogLoader', false).set('updatingId', 0);

    case types.REQUEST_UPDATE_TRAILER:
      return state.set('dialogLoader', true);

    case types.RECEIVE_UPDATE_TRAILER:
      return receiveUpdateTrailer(state, action).set('dialogLoader', false);

    case types.RECEIVE_UPDATE_TRAILER_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_DELETE_TRAILER:
      return state.set('dialogLoader', true);

    case types.RECEIVE_DELETE_TRAILER:
      return receiveDeleteTrailer(state, action).set('dialogLoader', false);

    case types.RECEIVE_DELETE_TRAILER_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_EXPORT_YARD_INVENTORY:
      return state.set('buttonLoader', true);

    case types.RECEIVE_EXPORT_YARD_INVENTORY:
      return state.set('buttonLoader', false);

    case types.RECEIVE_EXPORT_YARD_INVENTORY_FAIL:
      return state.set('buttonLoader', false);

    case types.REQUEST_GET_TRAILER_HISTORY:
      return state.set('trailerHistoryLoader', true);

    case types.RECEIVE_GET_TRAILER_HISTORY:
      return receiveGetTrailerHistory(state, action).set('trailerHistoryLoader', false);

    case types.RECEIVE_GET_TRAILER_HISTORY_FAIL:
      return state.set('trailerHistoryLoader', false);

    case types.REQUEST_GET_PHOTECTOR_EVENTS:
      return state.set('photectorEvents', []).set('photectorLoader', true);

    case types.RECEIVE_GET_PHOTECTOR_EVENTS:
      return receiveGetPhotectorEvents(state, action).set('photectorLoader', false);

    case types.RECEIVE_GET_PHOTECTOR_EVENTS_FAIL:
      return state.set('photectorEvents', []).set('photectorLoader', false);

    case types.RECEIVE_TRAILER_SESSION_DATA:
      return receiveTrailerSessionData(state, action);

    /**cases related to fetching trailer info based on reference id */

    case types.REQUEST_GET_TRAILER_LIST_INFO:
      return state.set('buttonLoader', true);

    case types.RECEIVE_GET_TRAILER_LIST_INFO:
      return receiveTrailerInfoBasedOnReference(state, action).set('buttonLoader', false);

    case types.RECEIVE_GET_TRAILER_LIST_FAIL_INFO:
      return state.set('buttonLoader', false);

    case types.SET_TRAILER_LIST_INFO_EMPTY:
      return state.set('referenceTrailer', Immutable.fromJS({}));

    /**cases related to fetching trailer info based on trailer number */

    case types.REQUEST_GET_TRAILER_INFO_BASED_ON_NUMBER:
      return state.set('buttonLoader', true);

    case types.RECEIVE_GET_TRAILER_INFO_BASED_ON_NUMBER:
      return state.set('numberBasedTrailer', Immutable.fromJS(action.trailer)).set('trailersListBasedOnNumber', action.trailerList).set('trailerSearchCountBasedOnNumber', action.trailerCount).set('buttonLoader', false);

    case types.RECEIVE_GET_TRAILER_FAIL_INFO_BASED_ON_NUMBER:
      return state.set('buttonLoader', false);

    case types.SET_TRAILER_LIST_INFO_BASED_ON_NUMBER_EMPTY:
      return state.set('numberBasedTrailer', Immutable.fromJS({})).set('trailersListBasedOnNumber', []).set('trailerSearchCountBasedOnNumber', 0);

    default:
      return state;
  }
};

export var selectors = {
  getTrailers: function getTrailers(state) {
    return state.trailer.get('list');
  },
  getTrailerById: function getTrailerById(state, id) {
    return state.trailer.get('list').find(function (trailer) {
      return trailer.get('ID') === id;
    });
  },
  getTrailerHistory: function getTrailerHistory(state) {
    return state.trailer.get('trailerHistory');
  },
  getTrailerHistoryLoader: function getTrailerHistoryLoader(state) {
    return state.trailer.get('trailerHistoryLoader');
  },
  getListLoader: function getListLoader(state) {
    return state.trailer.get('listLoader');
  },
  getButtonLoader: function getButtonLoader(state) {
    return state.trailer.get('buttonLoader');
  },
  getDialogLoader: function getDialogLoader(state) {
    return state.trailer.get('dialogLoader');
  },
  getDownloadLoader: function getDownloadLoader(state) {
    return state.trailer.get('downloadLoader');
  },
  getAlertLoader: function getAlertLoader(state) {
    return state.trailer.get('alertLoader');
  },
  getUpdatingId: function getUpdatingId(state) {
    return state.trailer.get('updatingId');
  },
  getItemsCount: function getItemsCount(state) {
    return state.trailer.get('itemsCount');
  },
  getAlertedId: function getAlertedId(state) {
    return state.trailer.get('alertedId');
  },
  getPhotectorLoader: function getPhotectorLoader(state) {
    return state.trailer.get('photectorLoader');
  },
  getPhotectorEvents: function getPhotectorEvents(state) {
    return state.trailer.get('photectorEvents');
  },
  getReferenceTrailer: function getReferenceTrailer(state) {
    return state.trailer.get('referenceTrailer');
  },
  getNumberBasedTrailer: function getNumberBasedTrailer(state) {
    return state.trailer.get('numberBasedTrailer');
  },
  getNumberBasedTrailerCount: function getNumberBasedTrailerCount(state) {
    return state.trailer.get('trailerSearchCountBasedOnNumber');
  },
  getNumberBasedTrailersList: function getNumberBasedTrailersList(state) {
    return state.trailer.get('trailersListBasedOnNumber');
  }
};
export default trailer;