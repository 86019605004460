var __extensible_get__ = require("extensible-runtime").immutable;

/**
 * Templates
 *
    {
        heading: ,
    }
    {
        key: ,
        path: ,
        exact: ,
        label: ,
        title: ,
        component: ,
        hideMenuItem: ,
        icon: ,
        badge: ,
        divider: ,
        clearance: ,
    },
 */
export { default } from './Routes';
export { default as unsecuredRoutes } from 'routes/unsecured';
export { default as securedRoutes } from 'routes/secured';
export { profileRoutes } from 'routes/secured';