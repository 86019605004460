var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var initPayload = function initPayload() {
  return Immutable.fromJS({
    SiteID: 0,
    Type: 0,
    OpenedUserID: 0,
    OpenedUserName: '',
    OpenedDate: '',
    ClosedUserID: 0,
    TrailerAuditState: 0,
    // 0 - none, 1 - on site, 3 - cond. departed
    ClosedUserName: '',
    ClosedDate: '',
    ID: 0,
    CreatedDate: '',
    CreatedBy: 0,
    CreatedByName: '',
    ModifiedDate: '',
    ModifiedBy: 0,
    Deleted: 0,
    DeletedDate: '',
    DeletedBy: 0,
    Code: '',
    Status: 0
  });
};
export var auditItemPayload = function auditItemPayload() {
  return Immutable.fromJS({
    AuditID: 0,
    TrailerID: 0,
    TrailerAuditState: 0,
    TrailerAuditStateName: '',
    ReeferFuelLevel: 0,
    BoxTemperature: '',
    UserID: 0,
    ID: 0,
    CreatedDate: '',
    CreatedBy: 0,
    CreatedByName: '',
    ModifiedDate: '',
    ModifiedBy: 0,
    Deleted: 0,
    DeletedDate: '',
    DeletedBy: 0,
    Code: '',
    Status: 0,
    Defrost: false
  });
};
export var sessionPayload = function sessionPayload(type, siteId) {
  return {
    Audit: {
      Type: type,
      SiteID: siteId
    }
  };
};
export var signOffPayload = function signOffPayload() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : auditItemPayload();
  return {
    AuditTrailer: {
      AuditID: obj.get('AuditID'),
      TrailerID: obj.get('TrailerID'),
      TrailerAuditState: obj.get('TrailerAuditState'),
      BoxTemperature: obj.get('BoxTemperature') !== null ? +obj.get('BoxTemperature') : null,
      ReeferFuelLevel: obj.get('ReeferFuelLevel'),
      Defrost: obj.get('Defrost')
    }
  };
};