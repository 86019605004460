var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var initPayload = function initPayload() {
  return Immutable.fromJS({
    Name: '',
    Description: '',
    GroupLocationIDs: []
  });
};
export var dockgroupPayload = function dockgroupPayload() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initPayload();
  return {
    DockGroup: {
      ID: obj.get('ID'),
      Name: obj.get('Name'),
      Description: obj.get('Description'),
      GroupLocationIDs: obj.get('GroupLocationIDs')
    }
  };
};