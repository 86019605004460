var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
import { locationNameComparator } from '../utils';
import { dockactivitySchemas } from '../schemas';
export var types = {
  GET_DOCKACTIVITY_LIST: 'GET_DOCKACTIVITY_LIST',
  REQUEST_GET_DOCKACTIVITY_LIST: 'REQUEST_GET_DOCKACTIVITY_LIST',
  RECEIVE_GET_DOCKACTIVITY_LIST: 'RECEIVE_GET_DOCKACTIVITY_LIST',
  RECEIVE_GET_DOCKACTIVITY_LIST_FAIL: 'RECEIVE_GET_DOCKACTIVITY_LIST_FAIL',
  UPDATE_DOCKACTIVITY_FILTERS: 'UPDATE_DOCKACTIVITY_FILTERS',
  REQUEST_UPDATE_DOCKACTIVITY_FILTERS: 'REQUEST_UPDATE_DOCKACTIVITY_FILTERS',
  RECEIVE_UPDATE_DOCKACTIVITY_FILTERS: 'RECEIVE_UPDATE_DOCKACTIVITY_FILTERS',
  RECEIVE_UPDATE_DOCKACTIVITY_FILTERS_FAIL: 'RECEIVE_UPDATE_DOCKACTIVITY_FILTERS_FAIL'
};
export var actions = {
  getDockActivityList: function getDockActivityList() {
    return {
      type: types.GET_DOCKACTIVITY_LIST
    };
  },
  requestGetDockActivityList: function requestGetDockActivityList() {
    return {
      type: types.REQUEST_GET_DOCKACTIVITY_LIST
    };
  },
  receiveGetDockActivityList: function receiveGetDockActivityList(dockactivities) {
    return {
      type: types.RECEIVE_GET_DOCKACTIVITY_LIST,
      dockactivities: dockactivities
    };
  },
  receiveGetDockActivityListFail: function receiveGetDockActivityListFail() {
    return {
      type: types.RECEIVE_GET_DOCKACTIVITY_LIST_FAIL
    };
  },
  updateDockActivityFilters: function updateDockActivityFilters(filters) {
    return {
      type: types.UPDATE_DOCKACTIVITY_FILTERS,
      filters: filters
    };
  },
  requestUpdateDockActivityFilters: function requestUpdateDockActivityFilters() {
    return {
      type: types.REQUEST_UPDATE_DOCKACTIVITY_FILTERS
    };
  },
  receiveUpdateDockActivityFilters: function receiveUpdateDockActivityFilters(filters) {
    return {
      type: types.RECEIVE_UPDATE_DOCKACTIVITY_FILTERS,
      filters: filters
    };
  },
  receiveUpdateDockActivityFiltersFail: function receiveUpdateDockActivityFiltersFail() {
    return {
      type: types.RECEIVE_UPDATE_DOCKACTIVITY_FILTERS_FAIL
    };
  }
};
var initialState = Immutable.fromJS({
  list: [],
  listLoader: false,
  filters: dockactivitySchemas.initFilters()
});

var receiveGetDockActivityList = function receiveGetDockActivityList(state, action) {
  return state.set('list', Immutable.fromJS(action.dockactivities).sortBy(function (dockactivity) {
    return dockactivity.get('DoorName');
  }, locationNameComparator // comparator function
  ));
};

var dockactivity = function dockactivity() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.REQUEST_GET_DOCKACTIVITY_LIST:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_DOCKACTIVITY_LIST:
      return receiveGetDockActivityList(state, action).set('listLoader', false);

    case types.RECEIVE_GET_DOCKACTIVITY_LIST_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_UPDATE_DOCKACTIVITY_FILTERS: // return state.set('dialogLoader', true);

    case types.RECEIVE_UPDATE_DOCKACTIVITY_FILTERS: // return receiveUpdateTask(state, action).set('dialogLoader', false);

    case types.RECEIVE_UPDATE_DOCKACTIVITY_FILTERS_FAIL: // return state.set('dialogLoader', false);

    case types.UPDATE_DOCKACTIVITY_FILTERS:
      return state.set('filters', action.filters);

    default:
      return state;
  }
};

export var selectors = {
  getDockActivityList: function getDockActivityList(state) {
    return state.dockactivity.get('list');
  },
  getListLoader: function getListLoader(state) {
    return state.dockactivity.get('listLoader');
  },
  getDockActivityFilters: function getDockActivityFilters(state) {
    return state.dockactivity.get('filters');
  }
};
export default dockactivity;