var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var types = {
  GET_DOCKGROUP: 'GET_DOCKGROUP',
  REQUEST_GET_DOCKGROUP: 'REQUEST_GET_DOCKGROUP',
  RECEIVE_GET_DOCKGROUP: 'RECEIVE_GET_DOCKGROUP',
  RECEIVE_GET_DOCKGROUP_FAIL: 'RECEIVE_GET_DOCKGROUP_FAIL',
  GET_DOCKGROUP_LIST: 'GET_DOCKGROUP_LIST',
  REQUEST_GET_DOCKGROUP_LIST: 'REQUEST_GET_DOCKGROUP_LIST',
  RECEIVE_GET_DOCKGROUP_LIST: 'RECEIVE_GET_DOCKGROUP_LIST',
  RECEIVE_GET_DOCKGROUP_LIST_FAIL: 'RECEIVE_GET_DOCKGROUP_LIST_FAIL',
  ADD_DOCKGROUP: 'ADD_DOCKGROUP',
  REQUEST_ADD_DOCKGROUP: 'REQUEST_ADD_DOCKGROUP',
  RECEIVE_ADD_DOCKGROUP: 'RECEIVE_ADD_DOCKGROUP',
  RECEIVE_ADD_DOCKGROUP_FAIL: 'RECEIVE_ADD_DOCKGROUP_FAIL',
  UPDATE_DOCKGROUP: 'UPDATE_DOCKGROUP',
  REQUEST_UPDATE_DOCKGROUP: 'REQUEST_UPDATE_DOCKGROUP',
  RECEIVE_UPDATE_DOCKGROUP: 'RECEIVE_UPDATE_DOCKGROUP',
  RECEIVE_UPDATE_DOCKGROUP_FAIL: 'RECEIVE_UPDATE_DOCKGROUP_FAIL',
  DELETE_DOCKGROUP: 'DELETE_DOCKGROUP',
  REQUEST_DELETE_DOCKGROUP: 'REQUEST_DELETE_DOCKGROUP',
  RECEIVE_DELETE_DOCKGROUP: 'RECEIVE_DELETE_DOCKGROUP',
  RECEIVE_DELETE_DOCKGROUP_FAIL: 'RECEIVE_DELETE_DOCKGROUP_FAIL'
};
export var actions = {
  getDockGroup: function getDockGroup(id) {
    return {
      type: types.GET_DOCKGROUP,
      id: id
    };
  },
  requestGetDockGroup: function requestGetDockGroup() {
    return {
      type: types.REQUEST_GET_DOCKGROUP
    };
  },
  receiveGetDockGroup: function receiveGetDockGroup(dockgroup) {
    return {
      type: types.RECEIVE_GET_DOCKGROUP,
      dockgroup: dockgroup
    };
  },
  receiveGetDockGroupFail: function receiveGetDockGroupFail() {
    return {
      type: types.RECEIVE_GET_DOCKGROUP_FAIL
    };
  },
  addDockGroup: function addDockGroup(payload, callback) {
    return {
      type: types.ADD_DOCKGROUP,
      payload: payload,
      callback: callback
    };
  },
  requestAddDockGroup: function requestAddDockGroup() {
    return {
      type: types.REQUEST_ADD_DOCKGROUP
    };
  },
  receiveAddDockGroup: function receiveAddDockGroup(dockgroup) {
    return {
      type: types.RECEIVE_ADD_DOCKGROUP,
      dockgroup: dockgroup
    };
  },
  receiveAddDockGroupFail: function receiveAddDockGroupFail() {
    return {
      type: types.RECEIVE_ADD_DOCKGROUP_FAIL
    };
  },
  updateDockGroup: function updateDockGroup(payload, callback) {
    return {
      type: types.UPDATE_DOCKGROUP,
      payload: payload,
      callback: callback
    };
  },
  requestUpdateDockGroup: function requestUpdateDockGroup() {
    return {
      type: types.REQUEST_UPDATE_DOCKGROUP
    };
  },
  receiveUpdateDockGroup: function receiveUpdateDockGroup(dockgroup) {
    return {
      type: types.RECEIVE_UPDATE_DOCKGROUP,
      dockgroup: dockgroup
    };
  },
  receiveUpdateDockGroupFail: function receiveUpdateDockGroupFail() {
    return {
      type: types.RECEIVE_UPDATE_DOCKGROUP_FAIL
    };
  },
  deleteDockGroup: function deleteDockGroup(id, callback) {
    return {
      type: types.DELETE_DOCKGROUP,
      id: id,
      callback: callback
    };
  },
  requestDeleteDockGroup: function requestDeleteDockGroup() {
    return {
      type: types.REQUEST_DELETE_DOCKGROUP
    };
  },
  receiveDeleteDockGroup: function receiveDeleteDockGroup(id) {
    return {
      type: types.RECEIVE_DELETE_DOCKGROUP,
      id: id
    };
  },
  receiveDeleteDockGroupFail: function receiveDeleteDockGroupFail() {
    return {
      type: types.RECEIVE_DELETE_DOCKGROUP_FAIL
    };
  },
  getDockGroupList: function getDockGroupList() {
    return {
      type: types.GET_DOCKGROUP_LIST
    };
  },
  requestGetDockGroupList: function requestGetDockGroupList() {
    return {
      type: types.REQUEST_GET_DOCKGROUP_LIST
    };
  },
  receiveGetDockGroupList: function receiveGetDockGroupList(dockgroups) {
    return {
      type: types.RECEIVE_GET_DOCKGROUP_LIST,
      dockgroups: dockgroups
    };
  },
  receiveGetDockGroupListFail: function receiveGetDockGroupListFail() {
    return {
      type: types.RECEIVE_GET_DOCKGROUP_LIST_FAIL
    };
  }
};
var initialState = Immutable.fromJS({
  list: [],
  listLoader: false,
  buttonLoader: false,
  formLoader: false,
  dialogLoader: false
});

var receiveGetDockGroupList = function receiveGetDockGroupList(state, action) {
  return state.set('list', Immutable.fromJS(action.dockgroups).map(function (dg) {
    return dg.set('GroupLocationIDs', dg.get('GroupLocations').map(function (loc) {
      return loc.get('LocationID');
    }));
  }));
};

var receiveAddDockGroup = function receiveAddDockGroup(state, action) {
  var dockgroupReturned = Immutable.fromJS(action.dockgroup);
  var groupLocationIDs = dockgroupReturned.get('GroupLocations').map(function (loc) {
    return loc.get('LocationID');
  }).toList();
  var newDG = dockgroupReturned.set('GroupLocationIDs', groupLocationIDs);
  return state.update('list', function (list) {
    return list.push(newDG);
  });
};

var receiveUpdateDockGroup = function receiveUpdateDockGroup(state, action) {
  var index = state.get('list').findIndex(function (dockgroup) {
    return dockgroup.get('ID') == action.dockgroup.ID;
  });
  var dockgroupReturned = Immutable.fromJS(action.dockgroup);
  var groupLocationIDs = dockgroupReturned.get('GroupLocations').map(function (loc) {
    return loc.get('LocationID');
  }).toList();
  var newDG = dockgroupReturned.set('GroupLocationIDs', groupLocationIDs);
  return state.setIn(['list', index], newDG);
};

var receiveDeleteDockGroup = function receiveDeleteDockGroup(state, action) {
  var index = state.get('list').findIndex(function (dockgroup) {
    return dockgroup.get('ID') === action.id;
  });
  return state.update('list', function (list) {
    return list["delete"](index);
  });
};

var dockgroup = function dockgroup() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.REQUEST_GET_DOCKGROUP:
      return state.set('formLoader', true);

    case types.RECEIVE_GET_DOCKGROUP:
      return state.set('formLoader', false);

    case types.RECEIVE_GET_DOCKGROUP_FAIL:
      return state.set('formLoader', false);

    case types.REQUEST_GET_DOCKGROUP_LIST:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_DOCKGROUP_LIST:
      return receiveGetDockGroupList(state, action).set('listLoader', false);

    case types.RECEIVE_GET_DOCKGROUP_LIST_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_ADD_DOCKGROUP:
      return state.set('buttonLoader', true);

    case types.RECEIVE_ADD_DOCKGROUP:
      return receiveAddDockGroup(state, action).set('buttonLoader', false);

    case types.RECEIVE_ADD_DOCKGROUP_FAIL:
      return state.set('buttonLoader', false);

    case types.REQUEST_UPDATE_DOCKGROUP:
      return state.set('dialogLoader', true);

    case types.RECEIVE_UPDATE_DOCKGROUP:
      return receiveUpdateDockGroup(state, action).set('dialogLoader', false);

    case types.RECEIVE_UPDATE_DOCKGROUP_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_DELETE_DOCKGROUP:
      return state.set('dialogLoader', true);

    case types.RECEIVE_DELETE_DOCKGROUP:
      return receiveDeleteDockGroup(state, action).set('dialogLoader', false);

    case types.RECEIVE_DELETE_DOCKGROUP_FAIL:
      return state.set('dialogLoader', false);

    default:
      return state;
  }
};

export var selectors = {
  getDockGroups: function getDockGroups(state) {
    return state.dockgroup.get('list');
  },
  getListLoader: function getListLoader(state) {
    return state.dockgroup.get('listLoader');
  },
  getButtonLoader: function getButtonLoader(state) {
    return state.dockgroup.get('buttonLoader');
  },
  getDialogLoader: function getDialogLoader(state) {
    return state.dockgroup.get('dialogLoader');
  },
  getFormLoader: function getFormLoader(state) {
    return state.dockgroup.get('formLoader');
  },
  getDockGroupById: function getDockGroupById(state, id) {
    return state.dockgroup.get('dockgroups').find(function (dockgroup) {
      return dockgroup.get('ID') === id;
    });
  }
};
export default dockgroup;