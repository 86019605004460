function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

var __extensible_get__ = require("extensible-runtime").immutable;

import axios from 'axios';
import Cookie from 'universal-cookie';
import { uiActions, userActions } from '../ducks';
var cookies = new Cookie();
var PROD = process.env.ENVIRONMENT === 'production';
var HTTPS = true;
export var URL_PROT = 'http' + (HTTPS ? 's' : '') + '://';
var API_UAT = 'spotlite-qat-api-yq.azurewebsites.net';
var API_DEV = 'spotlite-dev-api-yq.azurewebsites.net';
var API_PREPROD = 'spotlight-preprod-api-webapp-xj.azurewebsites.net';
var API_AZURE_DEV = 'spotlite-dev-api-yq.azurewebsites.net';
var API_AZURE_UAT = 'spotlite-qat-api-yq.azurewebsites.net';
var API_AZURE_PROD = 'spotlite-prod-api-lz.azurewebsites.net';
var API_PROD = 'spotlite-prod-api-lz.azurewebsites.net';
var API_LOCAL = 'http://localhost:5117';
var URL_BASE;

switch (process.env.ENVIRONMENT) {
  case 'production':
    URL_BASE = URL_PROT + API_PROD;
    break;

  case 'uat':
    URL_BASE = URL_PROT + API_UAT;
    break;

  case 'preprod':
    URL_BASE = URL_PROT + API_PREPROD;
    break;

  case 'dev':
    URL_BASE = URL_PROT + API_DEV;
    break;

  case 'azure_dev':
    URL_BASE = URL_PROT + API_AZURE_DEV;
    break;

  case 'azure_uat':
    URL_BASE = URL_PROT + API_AZURE_UAT;
    break;

  case 'azure_prod':
    URL_BASE = URL_PROT + API_AZURE_PROD;
    break;

  case 'local':
    URL_BASE = API_LOCAL;
    break;

  default:
    URL_BASE = URL_PROT + API_UAT;
    break;
}

export var URL_MAIN = URL_BASE + '/api';
/* const STATUS_MESSAGES = {
    [0]: 'OK',
    [-1]: 'Exception',
    [1]: 'User doesn\'t exist',
    [2]: 'Login Fail',
    [3]: 'Not Allowed',
    [4]: 'Username Exists',
    [5]: 'Password Doesn\'t Match',
    [6]: 'Validation Errors',
    [7]: 'Email Needs Validation',
    [8]: 'Invalid Username',
};*/

export var configureAjax = function configureAjax(store) {
  var dispatch = store.dispatch;
  var loggedIn = !!store.getState().user.getIn(['stateCookie', 'ID']);
  axios.defaults.baseURL = URL_MAIN;
  axios.interceptors.request.use(function (opt) {
    // get token fresh from the cookie right before every call
    var token = cookies.get('auth-token');

    if (!token && opt.url.indexOf('login') === -1) {
      dispatch(userActions.logout()); // return some defaults to avoid errors and cancel the request

      return {
        headers: {},
        method: opt.method,
        url: ''
      };
    }

    if (token) {
      opt.headers.Authorization = 'Bearer ' + (token || '');
    }

    return opt;
  }, function (error) {
    return Promise.reject(error);
  });
  axios.interceptors.response.use(function (response) {
    // the SessionUser data will not be updated here anymore to prevent unnecessary updates;
    // check Main component and rootSaga for the auth-check action
    return response;
  }, function (error) {
    if (!error.response) return Promise.reject(new Error('Network error'));

    if (error.response.status === 400) {
      // Bad request or missing data or bad formated data
      var formattedResponse = JSON.parse(JSON.stringify(error.response)); // api does not return consistant responses, need to check response data type

      if (typeof formattedResponse.data === 'string') {
        dispatch(uiActions.showErrorNotification(formattedResponse.data ? formattedResponse.data.Message : 'There was an error 1.'));
      } else if (_typeof(formattedResponse.data) === 'object') {
        var message = ''; // try to parse out the error messages from object and add to string

        try {
          if (typeof formattedResponse.data.title == 'string' && _typeof(formattedResponse.data.errors) == 'object') {
            message = formattedResponse.data.title;

            for (var err in formattedResponse.data.errors) {
              if (formattedResponse.data.errors.hasOwnProperty(err)) {
                message += ' ' + formattedResponse.data.errors[err].toString();
              }
            }
          }
        } catch (e) {} // if something went wrong trying to process the error object it will still give generic error message


        dispatch(uiActions.showErrorNotification(message ? message : 'There was an error with the data request.'));
      } else {
        // error with no or unrecognized response data
        dispatch(uiActions.showErrorNotification('There was an error.'));
      }

      return Promise.reject(new Error('400'));
    } else if (error.response.status === 401) {
      // Unauthorized
      if (loggedIn) {
        dispatch(userActions.logout());
        dispatch(uiActions.showErrorNotification('Your session expired, please log in.'));
      }
    } else if (error.response.status === 409) {
      // conflict with target resource
      var formattedResponse = JSON.parse(JSON.stringify(error.response)); // api does not return consistant responses, need to check response type

      if (typeof formattedResponse.data === 'string') {
        dispatch(uiActions.showErrorNotification(error.response.data ? error.response.data : 'There was a conflict with the intended change 1.'));
      } else if (_typeof(formattedResponse.data) === 'object') {
        var message = ''; // try to parse out the error messages from object and add to string

        try {
          if (typeof formattedResponse.data.title == 'string' && _typeof(formattedResponse.data.errors) == 'object') {
            message = formattedResponse.data.title;

            for (var _err in formattedResponse.data.errors) {
              if (formattedResponse.data.errors.hasOwnProperty(_err)) {
                message += ' ' + formattedResponse.data.errors[_err].toString();
              }
            }
          }
        } catch (e) {} // if something went wrong trying to process the error object it will still give generic error message


        dispatch(uiActions.showErrorNotification(message ? message : 'There was a conflict with the intended change 2.'));
      } else {
        // error with no or unrecognized response data
        dispatch(uiActions.showErrorNotification('There was a conflict with the intended change.'));
      }
    } else if (error.response.status === 500) {
      // Internal Server Error
      dispatch(uiActions.showErrorNotification('The server encountered an error.'));
    } else {
      // show generic message for all other unspecified error status
      dispatch(uiActions.showErrorNotification('There was an error.'));
    }

    return Promise.reject(error);
  });
};