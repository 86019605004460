var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var initFilters = function initFilters() {
  return Immutable.fromJS({
    skip: 0,
    take: 10,
    keyword: ''
  });
};
export var initPayload = function initPayload() {
  return Immutable.fromJS({
    Value: '',
    ID: 0,
    SiteID: 0
  });
};
export var initFreight = function initFreight() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initPayload();
  return {
    Freight: {
      Value: obj.get('Value'),
      ID: obj.get('ID'),
      SiteID: obj.get('SiteID')
    }
  };
};
export var freightPayload = function freightPayload() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initPayload();
  return {
    Freight: {
      Value: obj.get('Value'),
      ID: obj.get('ID')
    }
  };
};