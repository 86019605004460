var __extensible_get__ = require("extensible-runtime").immutable;

import { PropTypes } from 'prop-types';
import { checkAuth } from '../utils';

var WithAuthentication = function WithAuthentication(props) {
  var children = __extensible_get__(props, "children");

  var reverse = __extensible_get__(props, "reverse", false);

  var isLoggedIn = checkAuth();
  return reverse ? !isLoggedIn ? children : null : isLoggedIn ? children : null;
};

WithAuthentication.propTypes = {
  children: PropTypes.any.isRequired
};
export default WithAuthentication;