var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var initPayload = Immutable.fromJS({
  Name: '',
  ID: 0,
  CreatedDate: '',
  CreatedBy: 0,
  CreatedByName: '',
  ModifiedDate: '',
  ModifiedBy: 0,
  Deleted: 0,
  DeletedDate: '',
  DeletedBy: 0,
  Code: '',
  Status: 0
});
export var setListItem = function setListItem() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initPayload;
  return {
    ChecklistItem: {
      Name: obj.get('Name'),
      ID: obj.get('ID'),
      Status: obj.get('Status')
    }
  };
};
export var addListItem = function addListItem() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initPayload;
  return {
    ChecklistItem: {
      Name: obj.get('Name')
    }
  };
};