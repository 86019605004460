var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
import { checkIsInspectionEnabled, locationNameComparator } from '../utils';
export var types = {
  GET_LOOKUP_LIST: 'GET_LOOKUP_LIST',
  REQUEST_GET_LOOKUP_LIST: 'REQUEST_GET_LOOKUP_LIST',
  RECEIVE_GET_LOOKUP_LIST: 'RECEIVE_GET_LOOKUP_LIST',
  RECEIVE_GET_LOOKUP_LIST_FAIL: 'RECEIVE_GET_LOOKUP_LIST_FAIL',
  GET_SUGGESTION_LIST: 'GET_SUGGESTION_LIST',
  REQUEST_GET_SUGGESTION_LIST: 'REQUEST_GET_SUGGESTION_LIST',
  RECEIVE_GET_SUGGESTION_LIST: 'RECEIVE_GET_SUGGESTION_LIST',
  RECEIVE_GET_SUGGESTION_LIST_FAIL: 'RECEIVE_GET_SUGGESTION_LIST_FAIL'
};
export var actions = {
  getLookupList: function getLookupList(payload) {
    return {
      type: types.GET_LOOKUP_LIST,
      payload: payload
    };
  },
  requestGetLookupList: function requestGetLookupList() {
    return {
      type: types.REQUEST_GET_LOOKUP_LIST
    };
  },
  receiveGetLookupList: function receiveGetLookupList(lookups) {
    return {
      type: types.RECEIVE_GET_LOOKUP_LIST,
      lookups: lookups
    };
  },
  receiveGetLookupListFail: function receiveGetLookupListFail() {
    return {
      type: types.RECEIVE_GET_LOOKUP_LIST_FAIL
    };
  },
  getSuggestionList: function getSuggestionList(payload) {
    return {
      type: types.GET_SUGGESTION_LIST,
      payload: payload
    };
  },
  requestGetSuggestionList: function requestGetSuggestionList(lookupType) {
    return {
      type: types.REQUEST_GET_SUGGESTION_LIST,
      lookupType: lookupType
    };
  },
  receiveGetSuggestionList: function receiveGetSuggestionList(lookups) {
    return {
      type: types.RECEIVE_GET_SUGGESTION_LIST,
      lookups: lookups
    };
  },
  receiveGetSuggestionListFail: function receiveGetSuggestionListFail() {
    return {
      type: types.RECEIVE_GET_SUGGESTION_LIST_FAIL
    };
  }
};

var updaterFunctionSoft = function updaterFunctionSoft(array) {
  return function (value) {
    return array.length ? Immutable.fromJS(array) : value;
  };
};

var updaterFunctionHard = function updaterFunctionHard(array) {
  return function (value) {
    return Immutable.fromJS(array);
  };
};

var receiveGetLookupList = function receiveGetLookupList(state, _ref) {
  var lookups = __extensible_get__(_ref, "lookups");

  var obj = {
    trailers: [],
    locationTypes: [],
    shipments: [],
    scacs: [],
    locations: [],
    drivers: [],
    actions: [],
    timezones: [],
    taskTypes: [],
    trailerTypes: [],
    trailerDirectionTypes: [],
    trailerStatusTypes: [],
    trailerIntents: [],
    trailerFuelLevels: [],
    companyTypes: [],
    trailerStates: [],
    trailerPhoneCarrierTypes: [],
    tags: [],
    freights: [],
    availableTrailers: [],
    trailersInDoors: [],
    yards: [],
    siteUsers: [],
    siteTypes: [],
    inspectionFilters: []
  };
  /**adding to build inspection filters for trailer screen if setting is enabled */

  if (checkIsInspectionEnabled()) lookups.push({
    ID: 1,
    Name: 'Yes',
    Value: 'Yes',
    Type: 31
  }, {
    ID: 2,
    Name: 'No',
    Value: 'No',
    Type: 31
  });
  lookups.forEach(function (item) {
    switch (item.Type) {
      case 1:
        obj.trailers.push(item);
        break;

      case 2:
        obj.locationTypes.push(item);
        break;

      case 3:
        obj.shipments.push(item);
        break;

      case 4:
        obj.scacs.push(item);
        break;

      case 6:
        obj.locations.push(item);
        break;

      case 7:
        obj.drivers.push(item);
        break;

      case 8:
        obj.actions.push(item);
        break;

      case 9:
        obj.timezones.push(item);
        break;

      case 10:
        obj.taskTypes.push(item);
        break;

      case 11:
        obj.trailerTypes.push(item);
        break;

      case 12:
        obj.trailerDirectionTypes.push(item);
        break;

      case 13:
        obj.trailerStatusTypes.push(item);
        break;

      case 14:
        obj.trailerIntents.push(item);
        break;

      case 15:
        obj.trailerFuelLevels.push(item);
        break;

      case 16:
        obj.companyTypes.push(item);
        break;

      case 17:
        obj.trailerStates.push(item);
        break;

      case 21:
        obj.tags.push(item);
        break;

      case 24:
        obj.trailerPhoneCarrierTypes.push(item);
        break;

      case 25:
        obj.freights.push(item);
        break;

      case 26:
        obj.availableTrailers.push(item);
        break;

      case 27:
        obj.siteTypes.push(item);
        break;

      case 28:
        obj.yards.push(item);
        break;

      case 29:
        obj.siteUsers.push(item);
        break;

      case 30:
        obj.trailersInDoors.push(item);
        break;

      case 31:
        obj.inspectionFilters.push(item);
        break;
    }
  });
  var immutableLocations = updaterFunctionSoft(obj.locations)();
  var immutableYards = updaterFunctionSoft(obj.yards)();

  var immutableSortedLocations = function immutableSortedLocations(value) {
    return Immutable.isImmutable(immutableLocations) ? immutableLocations.sortBy(function (location) {
      return location.get('Name');
    }, locationNameComparator // comparator function
    ) : value;
  };

  var immutableSortedYards = function immutableSortedYards(value) {
    return Immutable.isImmutable(immutableYards) ? immutableYards.sortBy(function (location) {
      return location.get('Name');
    }, locationNameComparator // comparator function
    ) : value;
  };

  return state.update('lists', function (lists) {
    return lists.update('trailers', updaterFunctionHard(obj.trailers)).update('locationTypes', updaterFunctionSoft(obj.locationTypes)).update('shipments', updaterFunctionHard(obj.shipments)).update('scacs', updaterFunctionHard(obj.scacs)).update('tags', updaterFunctionSoft(obj.tags)).update('locations', immutableSortedLocations).update('yards', immutableSortedYards).update('drivers', updaterFunctionSoft(obj.drivers)).update('actions', updaterFunctionSoft(obj.actions)).update('timezones', updaterFunctionSoft(obj.timezones)).update('taskTypes', updaterFunctionSoft(obj.taskTypes)).update('trailerTypes', updaterFunctionSoft(obj.trailerTypes)).update('trailerDirectionTypes', updaterFunctionSoft(obj.trailerDirectionTypes)).update('trailerPhoneCarrierTypes', updaterFunctionSoft(obj.trailerPhoneCarrierTypes)).update('trailerStatusTypes', updaterFunctionSoft(obj.trailerStatusTypes)).update('trailerIntents', updaterFunctionSoft(obj.trailerIntents)).update('trailerFuelLevels', updaterFunctionSoft(obj.trailerFuelLevels)).update('companyTypes', updaterFunctionSoft(obj.companyTypes)).update('trailerStates', updaterFunctionSoft(obj.trailerStates)).update('freights', updaterFunctionSoft(obj.freights)).update('availableTrailers', updaterFunctionHard(obj.availableTrailers)).update('siteTypes', updaterFunctionSoft(obj.siteTypes)).update('trailersInDoors', updaterFunctionSoft(obj.trailersInDoors)).update('siteUsers', updaterFunctionSoft(obj.siteUsers)).update('inspections', updaterFunctionSoft(obj.inspectionFilters));
  });
};

var initialState = Immutable.fromJS({
  lists: {
    trailers: [],
    shipments: [],
    scacs: [],
    locations: [],
    yards: [],
    locationTypes: [],
    drivers: [],
    actions: [],
    timezones: [],
    taskTypes: [],
    trailerTypes: [],
    trailerDirectionTypes: [],
    trailerStatusTypes: [],
    trailerPhoneCarrierTypes: [],
    trailerIntents: [],
    trailerFuelLevels: [],
    companyTypes: [],
    trailerStates: [],
    tags: [],
    freights: [],
    availableTrailers: [],
    siteTypes: [],
    siteUsers: [],
    trailersInDoors: [],
    inspections: []
  },
  listLoader: false,
  scacLoader: false,
  trailerLoader: false,
  shipmentLoader: false,
  locationLoader: false,
  yardLoader: false
});

var getLoaderKey = function getLoaderKey(action) {
  switch (action.lookupType) {
    case 1:
      return 'trailerLoader';

    case 3:
      return 'shipmentLoader';

    case 4:
      return 'scacLoader';

    case 6:
      return 'locationLoader';

    case 28:
      return 'yardLoader';

    default:
      return 'listLoader';
  }
};

var setLoadingToFalse = function setLoadingToFalse(state) {
  return state.set('listLoader', false).set('scacLoader', false).set('trailerLoader', false).set('shipmentLoader', false).set('locationLoader', false).set('yardLoader', false);
};

var lookup = function lookup() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.REQUEST_GET_LOOKUP_LIST:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_LOOKUP_LIST:
      return receiveGetLookupList(state, action).set('listLoader', false);

    case types.RECEIVE_GET_LOOKUP_LIST_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_GET_SUGGESTION_LIST:
      return state.set(getLoaderKey(action), true);

    case types.RECEIVE_GET_SUGGESTION_LIST:
      return setLoadingToFalse(receiveGetLookupList(state, action));

    case types.RECEIVE_GET_SUGGESTION_LIST_FAIL:
      return setLoadingToFalse(state);

    default:
      return state;
  }
};

export var selectors = {
  getTrailers: function getTrailers(state) {
    return state.lookup.getIn(['lists', 'trailers']);
  },
  getShipments: function getShipments(state) {
    return state.lookup.getIn(['lists', 'shipments']);
  },
  getSCACs: function getSCACs(state) {
    return state.lookup.getIn(['lists', 'scacs']);
  },
  getTags: function getTags(state) {
    return state.lookup.getIn(['lists', 'tags']);
  },
  getLocations: function getLocations(state) {
    return state.lookup.getIn(['lists', 'locations']);
  },
  getYards: function getYards(state) {
    return state.lookup.getIn(['lists', 'yards']);
  },
  getLocationTypes: function getLocationTypes(state) {
    return state.lookup.getIn(['lists', 'locationTypes']);
  },
  getDrivers: function getDrivers(state) {
    return state.lookup.getIn(['lists', 'drivers']);
  },
  getActions: function getActions(state) {
    return state.lookup.getIn(['lists', 'actions']);
  },
  getTimeZones: function getTimeZones(state) {
    return state.lookup.getIn(['lists', 'timezones']);
  },
  getTaskTypes: function getTaskTypes(state) {
    return state.lookup.getIn(['lists', 'taskTypes']);
  },
  getFuelLevels: function getFuelLevels(state) {
    return state.lookup.getIn(['lists', 'trailerFuelLevels']);
  },
  getTrailerTypes: function getTrailerTypes(state) {
    return state.lookup.getIn(['lists', 'trailerTypes']);
  },
  getTrailerDirectionTypes: function getTrailerDirectionTypes(state) {
    return state.lookup.getIn(['lists', 'trailerDirectionTypes']);
  },
  getTrailerStatusTypes: function getTrailerStatusTypes(state) {
    return state.lookup.getIn(['lists', 'trailerStatusTypes']);
  },
  getTrailerIntents: function getTrailerIntents(state) {
    return state.lookup.getIn(['lists', 'trailerIntents']);
  },
  getCompanyTypes: function getCompanyTypes(state) {
    return state.lookup.getIn(['lists', 'companyTypes']);
  },
  getTrailerStates: function getTrailerStates(state) {
    return state.lookup.getIn(['lists', 'trailerStates']);
  },
  getListLoader: function getListLoader(state) {
    return state.lookup.get('listLoader');
  },
  getScacLoader: function getScacLoader(state) {
    return state.lookup.get('scacLoader');
  },
  getTrailerLoader: function getTrailerLoader(state) {
    return state.lookup.get('trailerLoader');
  },
  getShipmentLoader: function getShipmentLoader(state) {
    return state.lookup.get('shipmentLoader');
  },
  getLocationLoader: function getLocationLoader(state) {
    return state.lookup.get('locationLoader');
  },
  getPhoneCarriers: function getPhoneCarriers(state) {
    return state.lookup.getIn(['lists', 'trailerPhoneCarrierTypes']);
  },
  getFreights: function getFreights(state) {
    return state.lookup.getIn(['lists', 'freights']);
  },
  getAvailableTrailers: function getAvailableTrailers(state) {
    return state.lookup.getIn(['lists', 'availableTrailers']);
  },
  getSiteUsers: function getSiteUsers(state) {
    return state.lookup.getIn(['lists', 'siteUsers']);
  },
  getSiteTypes: function getSiteTypes(state) {
    return state.lookup.getIn(['lists', 'siteTypes']);
  },
  getTrailersInDoors: function getTrailersInDoors(state) {
    return state.lookup.getIn(['lists', 'trailersInDoors']);
  },
  getTrailerInspections: function getTrailerInspections(state) {
    return state.lookup.getIn(['lists', 'inspections']);
  }
};
export default lookup;