var __extensible_get__ = require("extensible-runtime").immutable;

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import ui from './ui';
import user from './user';
import site from './site';
import scac from './scac';
import task from './task';
import location from './location';
import trailer from './trailer';
import lookup from './lookup';
import message from './message';
import checklist from './checklist';
import audit from './audit';
import dockactivity from './dockactivity';
import dockgroup from './dockgroup';
import freight from './freight';
import scale from './scale';
import dockgroupdriver from './dockgroupdriver';
import rfid from './rfid';
export default (function (history) {
  return combineReducers({
    router: connectRouter(history),
    ui: ui,
    user: user,
    site: site,
    scac: scac,
    task: task,
    location: location,
    trailer: trailer,
    lookup: lookup,
    message: message,
    checklist: checklist,
    audit: audit,
    dockactivity: dockactivity,
    dockgroup: dockgroup,
    freight: freight,
    scale: scale,
    dockgroupdriver: dockgroupdriver,
    rfid: rfid
  });
});
export { types as uiTypes, actions as uiActions, selectors as uiSelectors } from './ui';
export { types as userTypes, actions as userActions, selectors as userSelectors } from './user';
export { types as siteTypes, actions as siteActions, selectors as siteSelectors } from './site';
export { types as scacTypes, actions as scacActions, selectors as scacSelectors } from './scac';
export { types as taskTypes, actions as taskActions, selectors as taskSelectors } from './task';
export { types as locationTypes, actions as locationActions, selectors as locationSelectors } from './location';
export { types as trailerTypes, actions as trailerActions, selectors as trailerSelectors } from './trailer';
export { types as lookupTypes, actions as lookupActions, selectors as lookupSelectors } from './lookup';
export { types as messageTypes, actions as messageActions, selectors as messageSelectors } from './message';
export { types as checklistTypes, actions as checklistActions, selectors as checklistSelectors } from './checklist';
export { types as auditTypes, actions as auditActions, selectors as auditSelectors } from './audit';
export { types as dockactivityTypes, actions as dockactivityActions, selectors as dockactivitySelectors } from './dockactivity';
export { types as dockgroupTypes, actions as dockgroupActions, selectors as dockgroupSelectors } from './dockgroup';
export { types as freightTypes, actions as freightActions, selectors as freightSelectors } from './freight';
export { types as scaleTypes, actions as scaleActions, selectors as scaleSelectors } from './scale';
export { types as dockgroupdriverTypes, actions as dockgroupdriverActions, selectors as dockgroupdriverSelectors } from './dockgroupdriver';
export { types as rfidTypes, actions as rfidActions, selectors as rfidSelectors } from './rfid';