var __extensible_get__ = require("extensible-runtime").immutable;

import axios from 'axios';
export var getChecklist = function getChecklist() {
  return axios.get('/checklistitems');
};
export var addListItem = function addListItem(payload) {
  return axios.post('/checklistitems', payload);
};
export var setListItem = function setListItem(payload) {
  return axios.put("/checklistitems/".concat(payload.ChecklistItem.ID), payload);
};
export var deleteListItem = function deleteListItem(id) {
  return axios["delete"]("/checklistitems/".concat(id));
};