var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var types = {
  GET_FREIGHT_LIST: 'GET_FREIGHT_LIST',
  REQUEST_GET_FREIGHT_LIST: 'REQUEST_GET_FREIGHT_LIST',
  RECEIVE_GET_FREIGHT_LIST: 'RECEIVE_GET_FREIGHT_LIST',
  RECEIVE_GET_FREIGHT_LIST_FAIL: 'RECEIVE_GET_FREIGHT_LIST_FAIL',
  GET_FREIGHT: 'GET_FREIGHT',
  REQUEST_GET_FREIGHT: 'REQUEST_GET_FREIGHT',
  RECEIVE_GET_FREIGHT: 'RECEIVE_GET_FREIGHT',
  RECEIVE_GET_FREIGHT_FAIL: 'RECEIVE_GET_FREIGHT_FAIL',
  ADD_FREIGHT: 'ADD_FREIGHT',
  REQUEST_ADD_FREIGHT: 'REQUEST_ADD_FREIGHT',
  RECEIVE_ADD_FREIGHT: 'RECEIVE_GET_FREIGHT',
  RECEIVE_ADD_FREIGHT_FAIL: 'RECEIVE_GET_FREIGHT_FAIL',
  UPDATE_FREIGHT: 'UPDATE_FREIGHT',
  REQUEST_UPDATE_FREIGHT: 'REQUEST_UPDATE_FREIGHT',
  RECEIVE_UPDATE_FREIGHT: 'RECEIVE_UPDATE_FREIGHT',
  RECEIVE_UPDATE_FREIGHT_FAIL: 'RECEIVE_UPDATE_FREIGHT_FAIL',
  DELETE_FREIGHT: 'DELETE_FREIGHT',
  REQUEST_DELETE_FREIGHT: 'REQUEST_DELETE_FREIGHT',
  RECEIVE_DELETE_FREIGHT: 'RECEIVE_DELETE_FREIGHT',
  RECEIVE_DELETE_FREIGHT_FAIL: 'RECEIVE_DELETE_FREIGHT_FAIL'
};
export var actions = {
  getFreightList: function getFreightList() {
    return {
      type: types.GET_FREIGHT_LIST
    };
  },
  requestGetFreightList: function requestGetFreightList() {
    return {
      type: types.REQUEST_GET_FREIGHT_LIST
    };
  },
  receiveGetFreightList: function receiveGetFreightList(freights) {
    return {
      type: types.RECEIVE_GET_FREIGHT_LIST,
      freights: freights
    };
  },
  receiveGetFreightListFail: function receiveGetFreightListFail() {
    return {
      type: types.RECEIVE_GET_FREIGHT_LIST_FAIL
    };
  },
  getFreight: function getFreight(id) {
    return {
      type: types.GET_FREIGHT,
      id: id
    };
  },
  requestGetFreight: function requestGetFreight() {
    return {
      type: types.REQUEST_GET_FREIGHT
    };
  },
  receiveGetFreight: function receiveGetFreight(freight) {
    return {
      type: types.RECEIVE_GET_FREIGHT,
      freight: freight
    };
  },
  receiveGetFreightFail: function receiveGetFreightFail() {
    return {
      type: types.RECEIVE_GET_FREIGHT_FAIL
    };
  },
  addFreight: function addFreight(payload, callback) {
    return {
      type: types.ADD_FREIGHT,
      payload: payload,
      callback: callback
    };
  },
  requestAddFreight: function requestAddFreight() {
    return {
      type: types.REQUEST_ADD_FREIGHT
    };
  },
  receiveAddFreight: function receiveAddFreight(freight) {
    return {
      type: types.RECEIVE_ADD_FREIGHT,
      freight: freight
    };
  },
  receiveAddFreightFail: function receiveAddFreightFail() {
    return {
      type: types.RECEIVE_ADD_FREIGHT_FAIL
    };
  },
  updateFreight: function updateFreight(payload, callback) {
    return {
      type: types.UPDATE_FREIGHT,
      payload: payload,
      callback: callback
    };
  },
  requestUpdateFreight: function requestUpdateFreight() {
    return {
      type: types.REQUEST_UPDATE_FREIGHT
    };
  },
  receiveUpdateFreight: function receiveUpdateFreight(freight) {
    return {
      type: types.RECEIVE_UPDATE_FREIGHT,
      freight: freight
    };
  },
  receiveUpdateFreightFail: function receiveUpdateFreightFail() {
    return {
      type: types.RECEIVE_UPDATE_FREIGHT_FAIL
    };
  },
  deleteFreight: function deleteFreight(id, callback) {
    return {
      type: types.DELETE_FREIGHT,
      id: id,
      callback: callback
    };
  },
  requestDeleteFreight: function requestDeleteFreight() {
    return {
      type: types.REQUEST_DELETE_FREIGHT
    };
  },
  receiveDeleteFreight: function receiveDeleteFreight(id) {
    return {
      type: types.RECEIVE_DELETE_FREIGHT,
      id: id
    };
  },
  receiveDeleteFreightFail: function receiveDeleteFreightFail() {
    return {
      type: types.RECEIVE_DELETE_FREIGHT_FAIL
    };
  }
};
var initialState = Immutable.fromJS({
  list: [],
  listLoader: false,
  dialogLoader: false,
  buttonLoader: false
});

var receiveGetFreightList = function receiveGetFreightList(state, action) {
  return state.set('list', Immutable.fromJS(action.freights));
};

var receiveAddFreight = function receiveAddFreight(state, action) {
  if (!action.freight) {
    return state;
  }

  return state.update('list', function (list) {
    return list.push(Immutable.fromJS(action.freight));
  });
};

var receiveUpdateFreight = function receiveUpdateFreight(state, action) {
  var index = state.get('list').findIndex(function (freight) {
    return freight.get('ID') == action.freight.ID;
  });
  return state.setIn(['list', index], Immutable.fromJS(action.freight));
};

var receiveDeleteFreight = function receiveDeleteFreight(state, action) {
  var index = state.get('list').findIndex(function (freight) {
    return freight.get('ID') === action.id;
  });
  return state.update('list', function (list) {
    return list["delete"](index);
  });
};

var freight = function freight() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.REQUEST_GET_FREIGHT:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_FREIGHT:
      return state;
    //   TODO

    case types.RECEIVE_GET_FREIGHT_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_GET_FREIGHT_LIST:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_FREIGHT_LIST:
      return receiveGetFreightList(state, action).set('listLoader', false);

    case types.RECEIVE_GET_FREIGHT_LIST_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_ADD_FREIGHT:
      return state.set('buttonLoader', true);

    case types.RECEIVE_ADD_FREIGHT:
      return receiveAddFreight(state, action).set('buttonLoader', false);

    case types.RECEIVE_ADD_FREIGHT_FAIL:
      return state.set('buttonLoader', false);

    case types.REQUEST_UPDATE_FREIGHT:
      return state.set('dialogLoader', true);

    case types.RECEIVE_UPDATE_FREIGHT:
      return receiveUpdateFreight(state, action).set('dialogLoader', false);

    case types.RECEIVE_UPDATE_FREIGHT_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_DELETE_FREIGHT:
      return state.set('dialogLoader', true);

    case types.RECEIVE_DELETE_FREIGHT:
      return receiveDeleteFreight(state, action).set('dialogLoader', false);

    case types.RECEIVE_DELETE_FREIGHT_FAIL:
      return state.set('dialogLoader', false);

    default:
      return state;
  }
};

export var selectors = {
  getListLoader: function getListLoader(state) {
    return state.freight.get('listLoader');
  },
  getFreights: function getFreights(state) {
    return state.freight.get('list');
  },
  getButtonLoader: function getButtonLoader(state) {
    return state.freight.get('buttonLoader');
  },
  getDialogLoader: function getDialogLoader(state) {
    return state.freight.get('dialogLoader');
  }
};
export default freight;