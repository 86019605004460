var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
var initObj = Immutable.fromJS({});
export var initFilters = function initFilters() {
  return Immutable.fromJS({
    roles: [0],
    name: '',
    status: -1,
    sites: [0],
    skip: 0,
    take: 10
  });
};
export var initAuthPayload = function initAuthPayload() {
  return {
    Email: '',
    Password: '',
    ConfirmPassword: '',
    FirstName: '',
    LastName: '',
    Username: '',
    PhoneNumber: ''
  };
};
export var login = function login() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initObj;
  return {
    Username: obj.get('Username'),
    Password: obj.get('Password')
  };
};
export var logout = function logout() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initObj;
  return {
    Username: obj.get('Username')
  };
};
export var getUserList = function getUserList() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initFilters;
  return {
    roles: obj.get('roles'),
    name: obj.get('name'),
    status: obj.get('status'),
    sites: obj.get('sites'),
    skip: obj.get('skip'),
    take: obj.get('take')
  };
};
export var addUser = function addUser() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initObj;
  return {
    User: {
      Email: obj.get('Email'),
      Password: obj.get('Password'),
      FirstName: obj.get('FirstName'),
      LastName: obj.get('LastName'),
      Username: obj.get('Username'),
      DateOfBirth: '06/25/1980',
      Title: 'Mr',
      UserNumber: '1',
      RoleIDs: obj.get('RoleIDs').toJS(),
      SiteIDs: obj.get('SiteIDs').filter(function (x) {
        return x !== -1;
      }).toJS(),
      PhoneNumber: obj.get('PhoneNumber'),
      ProductProtectionAlerts: obj.get('ProductProtectionAlerts'),
      ArrivalDepartureAlerts: obj.get('ArrivalDepartureAlerts'),
      TruckInspectionAlerts: obj.get('TruckInspectionAlerts')
    }
  };
};
export var updateUser = function updateUser() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initObj;
  return {
    User: {
      ID: obj.get('ID'),
      Email: obj.get('Email'),
      Password: obj.get('Password'),
      FirstName: obj.get('FirstName'),
      LastName: obj.get('LastName'),
      Username: obj.get('Username'),
      DateOfBirth: '06/25/1980',
      Title: 'Mr',
      UserNumber: '1',
      RoleIDs: obj.get('RoleIDs').toJS(),
      SiteIDs: obj.get('SiteIDs').filter(function (x) {
        return x !== -1;
      }).toJS(),
      PhoneNumber: obj.get('PhoneNumber'),
      ProductProtectionAlerts: obj.get('ProductProtectionAlerts'),
      ArrivalDepartureAlerts: obj.get('ArrivalDepartureAlerts'),
      TruckInspectionAlerts: obj.get('TruckInspectionAlerts')
    }
  };
};
export var initPayload = function initPayload() {
  return {
    Email: '',
    Password: '',
    ConfirmPassword: '',
    FirstName: '',
    LastName: '',
    Username: '',
    ID: 0,
    RoleIDs: [],
    SiteIDs: [0],
    DateOfBirth: '',
    Title: 'Mr',
    UserNumber: '1',
    PhoneNumber: '',
    ProductProtectionAlerts: false,
    ArrivalDepartureAlerts: false,
    TruckInspectionAlerts: false
  };
};
export var activateAccount = function activateAccount() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initObj;
  return {
    ActivationCode: obj.get('token')
  };
};
export var updateActiveSite = function updateActiveSite(id) {
  return {
    SiteID: id
  };
};