var __extensible_get__ = require("extensible-runtime").immutable;

import axios from 'axios';
import { checkIsInspectionEnabled, checkIsShipmentDateEnabled } from '../utils';
export var getTrailer = function getTrailer(id) {
  return axios.get("/trailers/".concat(id));
};
export var getTrailerList = function getTrailerList(payload) {
  var scacs = payload.scacs.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, scac) {
    return acc + "scacs=".concat(scac, "&");
  }, '');
  var types = payload.types.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, type) {
    return acc + "types=".concat(type, "&");
  }, '');
  var states = payload.states.filter(function (val) {
    return val !== 0 && val !== -1;
  }).reduce(function (acc, type) {
    return acc + "states=".concat(type, "&");
  }, '');
  var freights = payload.freights.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, freight) {
    return acc + "freights=".concat(freight, "&");
  }, '');
  var statusTypes = payload.statusTypes.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, statusType) {
    return acc + "statusTypes=".concat(statusType, "&");
  }, '');
  var directionTypes = payload.directionTypes.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, directionType) {
    return acc + "directionTypes=".concat(directionType, "&");
  }, '');
  var shipments = payload.shipments.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, shipments) {
    return acc + "shipments=".concat(shipments, "&");
  }, '');
  var locations = payload.locations.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, locations) {
    return acc + "locations=".concat(locations, "&");
  }, '');
  var auditType = payload.auditType ? "auditType=".concat(payload.auditType, "&") : '';
  var keyword = payload.keyword ? "keyword=".concat(payload.keyword, "&") : '';
  var rfidKeyword = payload.rfidKeyword ? "rfidKeyword=".concat(payload.rfidKeyword, "&") : '';
  var skip = typeof payload.skip !== 'undefined' ? "skip=".concat(payload.skip, "&") : '';
  var take = payload.take ? "take=".concat(payload.take, "&") : '';
  var unaudited = payload.UnauditedTrailers ? "unauditedTrailers=".concat(payload.UnauditedTrailers, "&") : '';
  var arrivalStartDate = payload.arrivalStartDate ? "arrivalStartDate=".concat(payload.arrivalStartDate, "&") : '';
  var arrivalEndDate = payload.arrivalEndDate ? "arrivalEndDate=".concat(payload.arrivalEndDate, "&") : '';
  var departureStartDate = payload.departureStartDate ? "departureStartDate=".concat(payload.departureStartDate, "&") : '';
  var departureEndDate = payload.departureEndDate ? "departureEndDate=".concat(payload.departureEndDate, "&") : '';
  var currentAuditOnly = payload.currentAuditOnly ? "currentAuditOnly=".concat(payload.currentAuditOnly, "&") : '';
  var sortKey = "sortKey=".concat(payload.sortKey, "&");
  var sortType = "sortType=".concat(payload.sortType, "&");
  var intent = payload.intents ? "intent=".concat(payload.intents, "&") : '';
  var PPPartialTrailer = payload.ProductProtectionPartialTrailer ? "ProductProtectionPartialTrailer=".concat(payload.ProductProtectionPartialTrailer, "&") : '';
  var ShipmentDate = payload.ShipmentDate && checkIsShipmentDateEnabled() ? "ShipmentDate=".concat(payload.ShipmentDate, "&") : '';
  var inspectionData = '';
  var inspectionValues = payload.inspections.filter(function (val) {
    return val !== 0 ? val : '';
  });

  if (inspectionValues.length > 0 && checkIsInspectionEnabled()) {
    var index = inspectionValues.indexOf(2);
    if (index !== -1) inspectionValues[index] = 0;
    inspectionData = "inspectionList=".concat(inspectionValues, "&");
  }

  var query = types + intent + states + statusTypes + directionTypes + keyword + skip + take + shipments + scacs + auditType + unaudited + arrivalStartDate + arrivalEndDate + departureStartDate + departureEndDate + currentAuditOnly + sortKey + sortType + rfidKeyword + locations + freights + ShipmentDate + inspectionData + PPPartialTrailer;
  return axios.get("/trailers".concat(query.length ? '?' : '').concat(query.slice(0, query.length - 1))); // cut the tailing '&'
};
export var addTrailer = function addTrailer(payload) {
  payload.Trailer.Number = payload.Trailer.Number ? payload.Trailer.Number.toUpperCase() : '';
  payload.Trailer.ShipmentNumber = payload.Trailer.ShipmentNumber ? payload.Trailer.ShipmentNumber.toUpperCase() : '';
  payload.Trailer.SealNumber = payload.Trailer.SealNumber ? payload.Trailer.SealNumber.toUpperCase() : '';
  return axios.post('/trailers', payload);
};
export var addTrailerToSession = function addTrailerToSession(id) {
  return axios.put("/trailers/".concat(id, "/load"), {});
};
export var updateTrailer = function updateTrailer(payload) {
  return axios.put("/trailers/".concat(payload.Trailer.ID), payload);
}; // export const sendTrailerAlert = id => axios.post(`/trailers/${id}/alert`, {});

export var sendTrailerAlert = function sendTrailerAlert(id) {
  return axios.post("/alerts/".concat(id), {});
};
export var updateTrailerState = function updateTrailerState(payload) {
  return axios.put('/trailers/State', payload);
};
export var deleteTrailer = function deleteTrailer(id) {
  return axios["delete"]("/trailers/".concat(id));
};
export var exportReport = function exportReport(payload) {
  return axios.post('/trailers/reportexport', payload);
};
export var getOldestEmptyIdleFromScac = function getOldestEmptyIdleFromScac(id) {
  return axios.get("/trailers/".concat(id, "/idle"));
};
export var getTrailerHistory = function getTrailerHistory(payload) {
  var trailerID = "TrailerID=".concat(payload.TrailerID, "&");
  var beginDate = "BeginDate=".concat(payload.BeginDate, "&");
  var endDate = "EndDate=".concat(payload.EndDate, "&");
  var query = trailerID + beginDate + endDate;
  return axios.get("/trailers/TrailerHistory?".concat(query.slice(0, query.length - 1))); // cut the tailing '&'
};
export var getTaskHistory = function getTaskHistory(payload) {
  var trailerID = "TrailerID=".concat(payload.TrailerID, "&");
  var beginDate = "BeginDate=".concat(payload.BeginDate, "&");
  var endDate = "EndDate=".concat(payload.EndDate, "&");
  var query = trailerID + beginDate + endDate;
  return axios.get("/tasks/TaskHistory?".concat(query.slice(0, query.length - 1))); // cut the tailing '&'
};
export var getPhotectorEvents = function getPhotectorEvents(payload) {
  var q = "trailerNumber=".concat(payload.TrailerNumber, "&");
  var startDate = "startDate=".concat(payload.StartDate, "&");
  var endDate = "endDate=".concat(payload.EndDate);
  var query = q + startDate + endDate;
  return axios.get("/Trailers/photectorImages?".concat(query));
};
export var getOldestIdleFromScac = function getOldestIdleFromScac(payload) {
  return axios.get("/trailers/idle/".concat(payload.ScacId, "/").concat(payload.TrailerType));
};
export var getTrailerBasedOnReferenceId = function getTrailerBasedOnReferenceId(payload) {
  var params = "siteName=".concat(payload.siteName);
  if (payload.refId) params = params + "&refNumber=".concat(payload.refId || '');
  return axios.get("/roc/getDetailsForRefNum?".concat(params));
};
/**method to fetch data based on number */

export var getTrailerInfo = function getTrailerInfo(payload) {
  var states = payload.states.filter(function (val) {
    return val !== -1;
  }).reduce(function (acc, type) {
    return acc + "states=".concat(type, "&");
  }, '');
  var keyword = payload.keyword ? "keyword=".concat(payload.keyword, "&") : '';
  var skip = typeof payload.skip !== 'undefined' ? "skip=".concat(payload.skip, "&") : '';
  var take = payload.take ? "take=".concat(payload.take, "&") : '';
  var sortKey = "sortKey=".concat(payload.sortKey, "&");
  var sortType = "sortType=".concat(payload.sortType, "&");
  var intent = payload.intents ? "intent=".concat(payload.intents, "&") : '';
  var query = intent + states + keyword + skip + take + sortKey + sortType + 'includeTagtype=true&';
  return axios.get("/trailers".concat(query.length ? '?' : '').concat(query.slice(0, query.length - 1))); // cut the tailing '&'
};