var __extensible_get__ = require("extensible-runtime").immutable;

import axios from 'axios';
export var getLocation = function getLocation(id) {
  return axios.get("/locations/".concat(id));
};
export var getLocationList = function getLocationList() {
  return axios.get('/locations');
};
export var addLocation = function addLocation(payload) {
  return axios.post('/locations', payload);
};
export var updateLocation = function updateLocation(payload) {
  return axios.put("/locations/".concat(payload.Location.ID), payload);
};
export var deleteLocation = function deleteLocation(id) {
  return axios["delete"]("/locations/".concat(id));
};
export var importLocations = function importLocations(payload) {
  return axios.post('/locations/import', payload);
};
export var downloadCSVExample = function downloadCSVExample() {
  return axios.get('/locations/importexample');
};
export var toggleDoorOOS = function toggleDoorOOS(payload) {
  var oos = "?oos=".concat(payload.DoorStatus);
  var query = "/locations/oos/".concat(payload.DoorID) + oos;
  return axios.put(query);
};