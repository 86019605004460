var __extensible_get__ = require("extensible-runtime").immutable;

import { useEffect } from 'react';
import { connect } from 'react-redux';
import { userActions, userSelectors } from 'ducks';

var Logout = function Logout(props) {
  useEffect(function () {
    props.logout(props.currentUser);
  }, []);
  return null;
};

export default connect(function (state) {
  return {
    currentUser: userSelectors.getUserInfo(state)
  };
}, function (dispatch) {
  return {
    logout: function logout(payload) {
      dispatch(userActions.logout(payload));
    }
  };
})(Logout);