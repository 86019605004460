function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var types = {
  SET_AFTER_LOGIN_PATH: 'SET_AFTER_LOGIN_PATH',
  RESET_AFTER_LOGIN_PATH: 'RESET_AFTER_LOGIN_PATH',
  UPDATE_ACTIVE_SITE_OBJECT: 'UPDATE_ACTIVE_SITE_OBJECT',
  LOGIN: 'LOGIN',
  REQUEST_LOGIN: 'REQUEST_LOGIN',
  RECEIVE_LOGIN: 'RECEIVE_LOGIN',
  RECEIVE_LOGIN_FAIL: 'RECEIVE_LOGIN_FAIL',
  CHECK_TOKEN: 'CHECK_TOKEN',
  REQUEST_CHECK_TOKEN: 'REQUEST_CHECK_TOKEN',
  RECEIVE_CHECK_TOKEN: 'RECEIVE_CHECK_TOKEN',
  RECEIVE_CHECK_TOKEN_FAIL: 'RECEIVE_CHECK_TOKEN_FAIL',
  ADD_USER: 'ADD_USER',
  REQUEST_ADD_USER: 'REQUEST_ADD_USER',
  RECEIVE_ADD_USER: 'RECEIVE_ADD_USER',
  RECEIVE_ADD_USER_FAIL: 'RECEIVE_ADD_USER_FAIL',
  UPDATE_USER: 'UPDATE_USER',
  REQUEST_UPDATE_USER: 'REQUEST_UPDATE_USER',
  RECEIVE_UPDATE_USER: 'RECEIVE_UPDATE_USER',
  RECEIVE_UPDATE_USER_FAIL: 'RECEIVE_UPDATE_USER_FAIL',
  DELETE_USER: 'DELETE_USER',
  REQUEST_DELETE_USER: 'REQUEST_DELETE_USER',
  RECEIVE_DELETE_USER: 'RECEIVE_DELETE_USER',
  RECEIVE_DELETE_USER_FAIL: 'RECEIVE_DELETE_USER_FAIL',
  UPDATE_ACTIVE_SITE: 'UPDATE_ACTIVE_SITE',
  REQUEST_UPDATE_ACTIVE_SITE: 'REQUEST_UPDATE_ACTIVE_SITE',
  RECEIVE_UPDATE_ACTIVE_SITE: 'RECEIVE_UPDATE_ACTIVE_SITE',
  RECEIVE_UPDATE_ACTIVE_SITE_FAIL: 'RECEIVE_UPDATE_ACTIVE_SITE_FAIL',
  GET_USER: 'GET_USER',
  REQUEST_GET_USER: 'REQUEST_GET_USER',
  RECEIVE_GET_USER: 'RECEIVE_GET_USER',
  GET_USER_LIST: 'GET_USER_LIST',
  REQUEST_GET_USER_LIST: 'REQUEST_GET_USER_LIST',
  RECEIVE_GET_USER_LIST: 'RECEIVE_GET_USER_LIST',
  RECEIVE_GET_USER_LIST_FAIL: 'RECEIVE_GET_USER_LIST_FAIL',
  LOGOUT: 'LOGOUT',
  REQUEST_LOGOUT: 'REQUEST_LOGOUT',
  RECEIVE_LOGOUT: 'RECEIVE_LOGOUT'
};
export var actions = {
  setAfterLoginPath: function setAfterLoginPath(afterLoginPath) {
    return {
      type: types.SET_AFTER_LOGIN_PATH,
      afterLoginPath: afterLoginPath
    };
  },
  resetAfterLoginPath: function resetAfterLoginPath() {
    return {
      type: types.RESET_AFTER_LOGIN_PATH
    };
  },
  updateActiveSiteObject: function updateActiveSiteObject(site) {
    return {
      type: types.UPDATE_ACTIVE_SITE_OBJECT,
      site: site
    };
  },
  login: function login(payload) {
    return {
      type: types.LOGIN,
      payload: payload
    };
  },
  requestLogin: function requestLogin() {
    return {
      type: types.REQUEST_LOGIN
    };
  },
  receiveLogin: function receiveLogin(user) {
    return {
      type: types.RECEIVE_LOGIN,
      user: user
    };
  },
  receiveLoginFail: function receiveLoginFail(status) {
    return {
      type: types.RECEIVE_LOGIN_FAIL,
      status: status
    };
  },
  addUser: function addUser(payload, callback) {
    return {
      type: types.ADD_USER,
      payload: payload,
      callback: callback
    };
  },
  requestAddUser: function requestAddUser() {
    return {
      type: types.REQUEST_ADD_USER
    };
  },
  receiveAddUser: function receiveAddUser(user) {
    return {
      type: types.RECEIVE_ADD_USER,
      user: user
    };
  },
  receiveAddUserFail: function receiveAddUserFail() {
    return {
      type: types.RECEIVE_ADD_USER_FAIL
    };
  },
  checkToken: function checkToken() {
    return {
      type: types.CHECK_TOKEN
    };
  },
  requestCheckToken: function requestCheckToken() {
    return {
      type: types.REQUEST_CHECK_TOKEN
    };
  },
  receiveCheckToken: function receiveCheckToken(user) {
    return {
      type: types.RECEIVE_CHECK_TOKEN,
      user: user
    };
  },
  receiveCheckTokenFail: function receiveCheckTokenFail() {
    return {
      type: types.RECEIVE_CHECK_TOKEN_FAIL
    };
  },
  updateUser: function updateUser(payload, callback) {
    return {
      type: types.UPDATE_USER,
      payload: payload,
      callback: callback
    };
  },
  requestUpdateUser: function requestUpdateUser() {
    return {
      type: types.REQUEST_UPDATE_USER
    };
  },
  receiveUpdateUser: function receiveUpdateUser(user) {
    return {
      type: types.RECEIVE_UPDATE_USER,
      user: user
    };
  },
  receiveUpdateUserFail: function receiveUpdateUserFail() {
    return {
      type: types.RECEIVE_UPDATE_USER_FAIL
    };
  },
  deleteUser: function deleteUser(id, callback) {
    return {
      type: types.DELETE_USER,
      id: id,
      callback: callback
    };
  },
  requestDeleteUser: function requestDeleteUser() {
    return {
      type: types.REQUEST_DELETE_USER
    };
  },
  receiveDeleteUser: function receiveDeleteUser(id) {
    return {
      type: types.RECEIVE_DELETE_USER,
      id: id
    };
  },
  receiveDeleteUserFail: function receiveDeleteUserFail() {
    return {
      type: types.RECEIVE_DELETE_USER_FAIL
    };
  },
  updateActiveSite: function updateActiveSite(payload, callback) {
    return {
      type: types.UPDATE_ACTIVE_SITE,
      payload: payload,
      callback: callback
    };
  },
  requestUpdateActiveSite: function requestUpdateActiveSite() {
    return {
      type: types.REQUEST_UPDATE_ACTIVE_SITE
    };
  },
  receiveUpdateActiveSite: function receiveUpdateActiveSite(response) {
    return {
      type: types.RECEIVE_UPDATE_ACTIVE_SITE,
      response: response
    };
  },
  receiveUpdateActiveSiteFail: function receiveUpdateActiveSiteFail() {
    return {
      type: types.RECEIVE_UPDATE_ACTIVE_SITE_FAIL
    };
  },
  getUser: function getUser(payload) {
    return {
      type: types.GET_USER,
      payload: payload
    };
  },
  requestGetUser: function requestGetUser() {
    return {
      type: types.REQUEST_GET_USER
    };
  },
  receiveGetUser: function receiveGetUser(user) {
    return {
      type: types.RECEIVE_GET_USER,
      user: user
    };
  },
  getUserList: function getUserList(payload) {
    return {
      type: types.GET_USER_LIST,
      payload: payload
    };
  },
  requestGetUserList: function requestGetUserList() {
    return {
      type: types.REQUEST_GET_USER_LIST
    };
  },
  receiveGetUserList: function receiveGetUserList(users, itemsCount) {
    return {
      type: types.RECEIVE_GET_USER_LIST,
      users: users,
      itemsCount: itemsCount
    };
  },
  receiveGetUserListFail: function receiveGetUserListFail() {
    return {
      type: types.RECEIVE_GET_USER_LIST_FAIL
    };
  },
  logout: function logout(payload) {
    return {
      type: types.LOGOUT,
      payload: payload
    };
  },
  requestLogout: function requestLogout() {
    return {
      type: types.REQUEST_LOGOUT
    };
  },
  receiveLogout: function receiveLogout() {
    return {
      type: types.RECEIVE_LOGOUT
    };
  }
};

var receiveGetUser = function receiveGetUser(state, action) {
  return state.set('selectedUser', Immutable.fromJS({
    UserID: action.ID,
    FirstName: action.FirstName,
    LastName: action.LastName,
    FullName: action.FullName
  }));
};

var receiveAddUser = function receiveAddUser(state, action) {
  return state.update('users', function (users) {
    return users.push(Immutable.fromJS(action.user));
  }).update('itemsCount', function (value) {
    return value + 1;
  });
};

var receiveUpdateUser = function receiveUpdateUser(state, action) {
  var index = state.get('users').findIndex(function (user) {
    return user.get('ID') === action.user.ID;
  });
  return state.setIn(['users', index], Immutable.fromJS(action.user));
};

var receiveDeleteUser = function receiveDeleteUser(state, _ref) {
  var id = __extensible_get__(_ref, "id");

  var index = state.get('users').findIndex(function (user) {
    return user.get('ID') === id;
  });
  return state.update('users', function (users) {
    return users["delete"](index);
  }).update('itemsCount', function (value) {
    return value - 1;
  });
};

var receiveGetUserList = function receiveGetUserList(state, action) {
  return state.set('users', Immutable.fromJS(action.users)).set('itemsCount', action.itemsCount);
};

var receiveLogin = function receiveLogin(state, action) {
  var user = __extensible_get__(action, "user");

  return state.set('stateCookie', Immutable.fromJS(_objectSpread({}, user)));
};

var receiveUpdateActiveSite = function receiveUpdateActiveSite(state, action) {
  var response = __extensible_get__(action, "response");

  return state.set('stateCookie', Immutable.fromJS(_objectSpread({}, response.SessionUser)));
};

var getLoginFailMessage = function getLoginFailMessage(status) {
  switch (status) {
    case 400:
      return 'There was an error.';

    case 401:
      return 'Please check your username and password';

    default:
      return '';
  }
};

var updateActiveSiteObject = function updateActiveSiteObject(state, action) {
  var site = __extensible_get__(action, "site");

  var index = state.getIn(['stateCookie', 'Sites']).findIndex(function (x) {
    return x.get('ID') === site.ID;
  });
  return state.setIn(['stateCookie', 'Sites', index], Immutable.fromJS(site));
};

var receiveLogout = function receiveLogout(state, action) {
  return state.set('stateCookie', Immutable.Map());
};

var initialState = Immutable.fromJS({
  stateCookie: Immutable.Map(),
  users: [],
  afterLoginPath: null,
  selectedUser: {},
  listLoader: false,
  buttonLoader: false,
  dialogLoader: false,
  bigLoader: false,
  loginFailMessage: '',
  itemsCount: 0
});

var user = function user() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.REQUEST_LOGIN:
      return state.set('buttonLoader', true).set('loginFailMessage', '');

    case types.RECEIVE_LOGIN:
      return receiveLogin(state, action).set('buttonLoader', false).set('loginFailMessage', '');

    case types.RECEIVE_LOGIN_FAIL:
      return state.set('buttonLoader', false).set('loginFailMessage', getLoginFailMessage(action.status));

    case types.SET_AFTER_LOGIN_PATH:
      return state.set('afterLoginPath', action.afterLoginPath);

    case types.RESET_AFTER_LOGIN_PATH:
      return state.set('afterLoginPath', null);

    case types.UPDATE_ACTIVE_SITE_OBJECT:
      return updateActiveSiteObject(state, action);

    case types.RECEIVE_GET_USER:
      return receiveGetUser(state, action);

    case types.RECEIVE_LOGOUT:
      return receiveLogout(state, action);

    case types.REQUEST_ADD_USER:
      return state.set('buttonLoader', true);

    case types.RECEIVE_ADD_USER:
      return receiveAddUser(state, action).set('buttonLoader', false);

    case types.RECEIVE_ADD_USER_FAIL:
      return state.set('buttonLoader', false);

    case types.REQUEST_CHECK_TOKEN:
      return state.set('bigLoader', true);

    case types.RECEIVE_CHECK_TOKEN:
      return receiveLogin(state, action).set('bigLoader', false);

    case types.RECEIVE_CHECK_TOKEN_FAIL:
      return state.set('bigLoader', false);

    case types.REQUEST_UPDATE_USER:
      return state.set('dialogLoader', true);

    case types.RECEIVE_UPDATE_USER:
      return receiveUpdateUser(state, action).set('dialogLoader', false);

    case types.RECEIVE_UPDATE_USER_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_DELETE_USER:
      return state.set('dialogLoader', true);

    case types.RECEIVE_DELETE_USER:
      return receiveDeleteUser(state, action).set('dialogLoader', false);

    case types.RECEIVE_DELETE_USER_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_GET_USER_LIST:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_USER_LIST:
      return receiveGetUserList(state, action).set('listLoader', false);

    case types.RECEIVE_GET_USER_LIST_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_UPDATE_ACTIVE_SITE:
      return state.set('dialogLoader', true);

    case types.RECEIVE_UPDATE_ACTIVE_SITE:
      return receiveUpdateActiveSite(state, action).set('dialogLoader', false);

    case types.RECEIVE_UPDATE_ACTIVE_SITE_FAIL:
      return state.set('dialogLoader', false);

    default:
      return state;
  }
};

var getUserRoles = function getUserRoles(state) {
  if (state.user.getIn(['stateCookie', 'Roles'])) {
    return state.user.getIn(['stateCookie', 'Roles']).toJS().map(function (role) {
      return role.Name.toLowerCase();
    });
  } else {
    return [];
  }
};

var getClearance = function getClearance(state) {
  var user = state.user.get('stateCookie');
  if (user.get('IsSuperAdmin')) return 'superadmin';
  if (user.get('IsAdmin')) return 'admin';
  if (user.get('IsUser')) return 'user';
  if (user.get('IsYardControl')) return 'yardcontrol';
  if (user.get('IsDriver')) return 'driver';
  if (user.get('IsDriverSupervisor')) return 'driversupervisor';
  if (user.get('IsNonWorkingDriver')) return 'nonworkingdriver';
};

var getActiveSite = function getActiveSite(state) {
  var siteId = state.user.getIn(['stateCookie', 'ActiveSiteID']);
  return state.site.get('list').find(function (site) {
    return site.get('ID') === siteId;
  });
};

var getActiveSiteRFIDStatus = function getActiveSiteRFIDStatus(state) {
  var siteId = state.user.getIn(['stateCookie', 'ActiveSiteID']);
  var sites = state.site.get('list');
  var activeSite = sites ? sites.find(function (site) {
    return site.get('ID') === siteId;
  }) : null;
  return activeSite ? activeSite.get('ActiveSiteRFID') : false;
};

var getActiveSiteType = function getActiveSiteType(state) {
  var siteId = state.user.getIn(['stateCookie', 'ActiveSiteID']);
  var sites = state.site.get('list');
  var activeSite = sites ? sites.find(function (site) {
    return site.get('ID') === siteId;
  }) : null;
  return activeSite ? activeSite.get('SiteType') : false;
};

var getActiveSiteManagedStatus = function getActiveSiteManagedStatus(state) {
  var siteId = state.user.getIn(['stateCookie', 'ActiveSiteID']);
  var sites = state.site.get('list');
  var activeSite = sites ? sites.find(function (site) {
    return site.get('ID') === siteId;
  }) : null;
  return activeSite ? activeSite.get('Managed') : false;
};

var getActiveSitePlusStatus = function getActiveSitePlusStatus(state) {
  var siteId = state.user.getIn(['stateCookie', 'ActiveSiteID']);
  var sites = state.site.get('list');
  var activeSite = sites ? sites.find(function (site) {
    return site.get('ID') === siteId;
  }) : null;
  return activeSite ? activeSite.get('PlusSite') : 0;
};

export var selectors = {
  getLoggedIn: function getLoggedIn(state) {
    return state.user.getIn(['stateCookie', 'UserID']) > 0;
  },
  getAfterLoginPath: function getAfterLoginPath(state) {
    return state.user.get('afterLoginPath');
  },
  getUserInfo: function getUserInfo(state) {
    return state.user.get('stateCookie');
  },
  getUserRoles: getUserRoles,
  getClearance: getClearance,
  getActiveSite: getActiveSite,
  getActiveSiteType: getActiveSiteType,
  getActiveSiteRFIDStatus: getActiveSiteRFIDStatus,
  getActiveSiteId: function getActiveSiteId(state) {
    return state.user.getIn(['stateCookie', 'ActiveSiteID']);
  },
  getActiveSiteManagedStatus: getActiveSiteManagedStatus,
  getUserList: function getUserList(state) {
    return state.user.get('users');
  },
  getItemsCount: function getItemsCount(state) {
    return state.user.get('itemsCount');
  },
  getUserByID: function getUserByID(state, id) {
    return state.user.getIn(['users', id]);
  },
  getListLoader: function getListLoader(state) {
    return state.user.get('listLoader');
  },
  getButtonLoader: function getButtonLoader(state) {
    return state.user.get('buttonLoader');
  },
  getDialogLoader: function getDialogLoader(state) {
    return state.user.get('dialogLoader');
  },
  getBigLoader: function getBigLoader(state) {
    return state.user.get('bigLoader');
  },
  getLoginFailMessage: function getLoginFailMessage(state) {
    return state.user.get('loginFailMessage');
  }
};
export default user;