var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var types = {
  SHOW_CONFIRMATION_DIALOG: 'SHOW_CONFIRMATION_DIALOG',
  HIDE_CONFIRMATION_DIALOG: 'HIDE_CONFIRMATION_DIALOG',
  SHOW_SNACKBAR: 'SHOW_SNACKBAR',
  SHOW_ERROR_SNACKBAR: 'SHOW_ERROR_SNACKBAR',
  HIDE_SNACKBAR: 'HIDE_SNACKBAR',
  SWITCH_MENU_STYLE: 'SWITCH_MENU_STYLE',
  SET_DRAG_FLAG: 'SET_DRAG_FLAG'
};
export var actions = {
  showNotification: function showNotification(message) {
    var callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
    return {
      type: types.SHOW_SNACKBAR,
      message: message,
      error: false,
      callback: callback
    };
  },
  showErrorNotification: function showErrorNotification(message) {
    var callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
    return {
      type: types.SHOW_SNACKBAR,
      message: message,
      error: true,
      callback: callback
    };
  },
  hideNotification: function hideNotification() {
    return {
      type: types.HIDE_SNACKBAR
    };
  },
  switchMenuStyle: function switchMenuStyle() {
    return {
      type: types.SWITCH_MENU_STYLE
    };
  },
  switchAdminView: function switchAdminView() {
    return {
      type: types.SWITCH_ADMIN_VIEW
    };
  },
  setDragFlag: function setDragFlag(value) {
    return {
      type: types.SET_DRAG_FLAG,
      value: value
    };
  }
};
var initialState = Immutable.fromJS({
  snackbar: {
    open: false,
    message: '',
    error: false,
    callback: function callback() {}
  },
  menuCollapsed: false,
  dragging: false
});

function showNotification(state, action) {
  return state.update('snackbar', function (item) {
    return item.merge({
      open: true,
      message: action.message,
      error: action.error
    });
  });
}

function hideNotification(state) {
  return state.update('snackbar', function (item) {
    return item.merge({
      open: false,
      error: false
    });
  });
}

var main = function main() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.TOGGLE_SIDEBAR:
      return state.set('sidebarOpen', !state.sidebarOpen);

    case types.UPDATE_PAGE_TITLE:
      return state.set('pageTitle', action.title);

    case types.SHOW_SNACKBAR:
      return showNotification(state, action);

    case types.HIDE_SNACKBAR:
      return hideNotification(state);

    case types.SWITCH_MENU_STYLE:
      return state.update('menuCollapsed', function (value) {
        return !value;
      });

    case types.SET_DRAG_FLAG:
      return state.set('dragging', action.value);

    default:
      return state;
  }
};

export var selectors = {
  getMenuCollapsed: function getMenuCollapsed(state) {
    return state.ui.get('menuCollapsed');
  }
};
export default main;