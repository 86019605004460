var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var types = {
  GET_SCAC: 'GET_SCAC',
  REQUEST_GET_SCAC: 'REQUEST_GET_SCAC',
  RECEIVE_GET_SCAC: 'RECEIVE_GET_SCAC',
  RECEIVE_GET_SCAC_FAIL: 'RECEIVE_GET_SCAC_FAIL',
  GET_SCAC_LIST: 'GET_SCAC_LIST',
  REQUEST_GET_SCAC_LIST: 'REQUEST_GET_SCAC_LIST',
  RECEIVE_GET_SCAC_LIST: 'RECEIVE_GET_SCAC_LIST',
  RECEIVE_GET_SCAC_LIST_FAIL: 'RECEIVE_GET_SCAC_LIST_FAIL',
  ADD_SCAC: 'ADD_SCAC',
  REQUEST_ADD_SCAC: 'REQUEST_ADD_SCAC',
  RECEIVE_ADD_SCAC: 'RECEIVE_ADD_SCAC',
  RECEIVE_ADD_SCAC_FAIL: 'RECEIVE_ADD_SCAC_FAIL',
  UPDATE_SCAC: 'UPDATE_SCAC',
  REQUEST_UPDATE_SCAC: 'REQUEST_UPDATE_SCAC',
  RECEIVE_UPDATE_SCAC: 'RECEIVE_UPDATE_SCAC',
  RECEIVE_UPDATE_SCAC_FAIL: 'RECEIVE_UPDATE_SCAC_FAIL',
  DELETE_SCAC: 'DELETE_SCAC',
  REQUEST_DELETE_SCAC: 'REQUEST_DELETE_SCAC',
  RECEIVE_DELETE_SCAC: 'RECEIVE_DELETE_SCAC',
  RECEIVE_DELETE_SCAC_FAIL: 'RECEIVE_DELETE_SCAC_FAIL',
  IMPORT_SCACS: 'IMPORT_SCACS',
  REQUEST_IMPORT_SCACS: 'REQUEST_IMPORT_SCACS',
  RECEIVE_IMPORT_SCACS: 'RECEIVE_IMPORT_SCACS',
  RECEIVE_IMPORT_SCACS_FAIL: 'RECEIVE_IMPORT_SCACS_FAIL',
  DOWNLOAD_SCAC_CSV_EXAMPLE: 'DOWNLOAD_SCAC_CSV_EXAMPLE',
  REQUEST_DOWNLOAD_SCAC_CSV_EXAMPLE: 'REQUEST_DOWNLOAD_SCAC_CSV_EXAMPLE',
  RECEIVE_DOWNLOAD_SCAC_CSV_EXAMPLE: 'RECEIVE_DOWNLOAD_SCAC_CSV_EXAMPLE',
  RECEIVE_DOWNLOAD_SCAC_CSV_EXAMPLE_FAIL: 'RECEIVE_DOWNLOAD_SCAC_CSV_EXAMPLE_FAIL'
};
export var actions = {
  getScac: function getScac(id) {
    return {
      type: types.GET_SCAC,
      id: id
    };
  },
  requestGetScac: function requestGetScac() {
    return {
      type: types.REQUEST_GET_SCAC
    };
  },
  receiveGetScac: function receiveGetScac(scac) {
    return {
      type: types.RECEIVE_GET_SCAC,
      scac: scac
    };
  },
  receiveGetScacFail: function receiveGetScacFail() {
    return {
      type: types.RECEIVE_GET_SCAC_FAIL
    };
  },
  addScac: function addScac(payload, callback) {
    return {
      type: types.ADD_SCAC,
      payload: payload,
      callback: callback
    };
  },
  requestAddScac: function requestAddScac() {
    return {
      type: types.REQUEST_ADD_SCAC
    };
  },
  receiveAddScac: function receiveAddScac(scac) {
    return {
      type: types.RECEIVE_ADD_SCAC,
      scac: scac
    };
  },
  receiveAddScacFail: function receiveAddScacFail() {
    return {
      type: types.RECEIVE_ADD_SCAC_FAIL
    };
  },
  updateScac: function updateScac(payload, callback) {
    return {
      type: types.UPDATE_SCAC,
      payload: payload,
      callback: callback
    };
  },
  requestUpdateScac: function requestUpdateScac() {
    return {
      type: types.REQUEST_UPDATE_SCAC
    };
  },
  receiveUpdateScac: function receiveUpdateScac(scac) {
    return {
      type: types.RECEIVE_UPDATE_SCAC,
      scac: scac
    };
  },
  receiveUpdateScacFail: function receiveUpdateScacFail() {
    return {
      type: types.RECEIVE_UPDATE_SCAC_FAIL
    };
  },
  deleteScac: function deleteScac(id, callback) {
    return {
      type: types.DELETE_SCAC,
      id: id,
      callback: callback
    };
  },
  requestDeleteScac: function requestDeleteScac() {
    return {
      type: types.REQUEST_DELETE_SCAC
    };
  },
  receiveDeleteScac: function receiveDeleteScac(id) {
    return {
      type: types.RECEIVE_DELETE_SCAC,
      id: id
    };
  },
  receiveDeleteScacFail: function receiveDeleteScacFail() {
    return {
      type: types.RECEIVE_DELETE_SCAC_FAIL
    };
  },
  getScacList: function getScacList(payload) {
    return {
      type: types.GET_SCAC_LIST,
      payload: payload
    };
  },
  requestGetScacList: function requestGetScacList() {
    return {
      type: types.REQUEST_GET_SCAC_LIST
    };
  },
  receiveGetScacList: function receiveGetScacList(scacs, itemsCount) {
    return {
      type: types.RECEIVE_GET_SCAC_LIST,
      scacs: scacs,
      itemsCount: itemsCount
    };
  },
  receiveGetScacListFail: function receiveGetScacListFail() {
    return {
      type: types.RECEIVE_GET_SCAC_LIST_FAIL
    };
  },
  importScacs: function importScacs(payload, callback) {
    return {
      type: types.IMPORT_SCACS,
      payload: payload,
      callback: callback
    };
  },
  requestImportScacs: function requestImportScacs() {
    return {
      type: types.REQUEST_IMPORT_SCACS
    };
  },
  receiveImportScacs: function receiveImportScacs(scac) {
    return {
      type: types.RECEIVE_IMPORT_SCACS,
      scac: scac
    };
  },
  receiveImportScacsFail: function receiveImportScacsFail() {
    return {
      type: types.RECEIVE_IMPORT_SCACS_FAIL
    };
  },
  downloadCSVExample: function downloadCSVExample(callback) {
    return {
      type: types.DOWNLOAD_SCAC_CSV_EXAMPLE,
      callback: callback
    };
  },
  requestDownloadCSVExample: function requestDownloadCSVExample() {
    return {
      type: types.REQUEST_DOWNLOAD_SCAC_CSV_EXAMPLE
    };
  },
  receiveDownloadCSVExample: function receiveDownloadCSVExample() {
    return {
      type: types.RECEIVE_DOWNLOAD_SCAC_CSV_EXAMPLE
    };
  },
  receiveDownloadCSVExampleFail: function receiveDownloadCSVExampleFail() {
    return {
      type: types.RECEIVE_DOWNLOAD_SCAC_CSV_EXAMPLE_FAIL
    };
  }
};
var initialState = Immutable.fromJS({
  list: [],
  itemsCount: 0,
  currentScac: {},
  editedScac: {},
  listLoader: false,
  buttonLoader: false,
  importLoader: false,
  dialogLoader: false,
  downloadLoader: false
});

var receiveGetScacList = function receiveGetScacList(state, action) {
  return state.set('list', Immutable.fromJS(action.scacs)).set('itemsCount', action.itemsCount);
};

var receiveAddScac = function receiveAddScac(state, action) {
  return state.update('list', function (list) {
    return list.push(Immutable.fromJS(action.scac));
  });
};

var receiveUpdateScac = function receiveUpdateScac(state, action) {
  var index = state.get('list').findIndex(function (scac) {
    return scac.get('ID') == action.scac.ID;
  });
  return state.setIn(['list', index], Immutable.fromJS(action.scac));
};

var receiveDeleteScac = function receiveDeleteScac(state, action) {
  var index = state.get('list').findIndex(function (scac) {
    return scac.get('ID') === action.id;
  });
  return state.update('list', function (list) {
    return list["delete"](index);
  });
};

var scac = function scac() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.REQUEST_GET_SCAC:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_SCAC:
      return state.set('currentScac', Immutable.fromJS(action.scac || {})) // avoid setting null
      .set('listLoader', false);

    case types.RECEIVE_GET_SCAC_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_GET_SCAC_LIST:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_SCAC_LIST:
      return receiveGetScacList(state, action).set('listLoader', false);

    case types.RECEIVE_GET_SCAC_LIST_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_ADD_SCAC:
      return state.set('buttonLoader', true);

    case types.RECEIVE_ADD_SCAC:
      return receiveAddScac(state, action).set('buttonLoader', false);

    case types.RECEIVE_ADD_SCAC_FAIL:
      return state.set('buttonLoader', false);

    case types.REQUEST_UPDATE_SCAC:
      return state.set('dialogLoader', true);

    case types.RECEIVE_UPDATE_SCAC:
      return receiveUpdateScac(state, action).set('dialogLoader', false);

    case types.RECEIVE_UPDATE_SCAC_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_DELETE_SCAC:
      return state.set('dialogLoader', true);

    case types.RECEIVE_DELETE_SCAC:
      return receiveDeleteScac(state, action).set('dialogLoader', false);

    case types.RECEIVE_DELETE_SCAC_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_IMPORT_SCACS:
      return state.set('importLoader', true);

    case types.RECEIVE_IMPORT_SCACS:
      return state.set('importLoader', false);

    case types.RECEIVE_IMPORT_SCACS_FAIL:
      return state.set('importLoader', false);

    case types.REQUEST_DOWNLOAD_SCAC_CSV_EXAMPLE:
      return state.set('downloadLoader', true);

    case types.RECEIVE_DOWNLOAD_SCAC_CSV_EXAMPLE:
      return state.set('downloadLoader', false);

    case types.RECEIVE_DOWNLOAD_SCAC_CSV_EXAMPLE_FAIL:
      return state.set('downloadLoader', false);

    default:
      return state;
  }
};

export var selectors = {
  getCurrentScac: function getCurrentScac(state) {
    return state.scac.get('currentScac');
  },
  getScacs: function getScacs(state) {
    return state.scac.get('list');
  },
  getListLoader: function getListLoader(state) {
    return state.scac.get('listLoader');
  },
  getButtonLoader: function getButtonLoader(state) {
    return state.scac.get('buttonLoader');
  },
  getItemsCount: function getItemsCount(state) {
    return state.scac.get('itemsCount');
  },
  getImportLoader: function getImportLoader(state) {
    return state.scac.get('importLoader');
  },
  getDialogLoader: function getDialogLoader(state) {
    return state.scac.get('dialogLoader');
  },
  getDownloadLoader: function getDownloadLoader(state) {
    return state.scac.get('downloadLoader');
  }
};
export default scac;