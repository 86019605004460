var __extensible_get__ = require("extensible-runtime").immutable;

import { toJss } from '../utils';
import { createTheme } from '@material-ui/core';
var brandColors = {
  primary: '#F6B21F',
  secondary: '#F16623',
  brandDark: '#F16623',
  darkPaper: '#3E4347',
  inputBorder: '#80BDFF',
  success: '#1BB99A',
  successDark: 'rgba(27, 185, 154, 1)',
  error: '#FF5D48',
  info: '#3DB9DC',
  purple: '#8A2BE2',
  black: '#000000'
};
var defaultTheme = createTheme({
  typography: {
    useNextVariants: true
  }
});
export var theme = createTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    common: {
      brandDark: brandColors.brandDark,
      darkPaper: brandColors.darkPaper
    },
    primary: {
      main: brandColors.primary,
      contrastText: defaultTheme.palette.common.white
    },
    secondary: {
      main: brandColors.secondary,
      contrastText: defaultTheme.palette.common.white
    },
    success: {
      main: brandColors.success,
      dark: brandColors.success,
      contrastText: defaultTheme.palette.common.white
    },
    error: {
      main: brandColors.error,
      contrastText: defaultTheme.palette.common.white
    },
    info: {
      main: brandColors.info,
      contrastText: defaultTheme.palette.common.white
    },
    purple: {
      main: brandColors.purple,
      contrastText: defaultTheme.palette.common.white
    },
    types: {
      dark: {
        background: {
          paper: brandColors.darkPaper
        }
      }
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 4,
        color: defaultTheme.palette.common.white
      },
      sizeSmall: {
        minWidth: 32
      }
    },
    MuiInput: {
      input: {
        padding: '10px 12px'
      },
      inputMultiline: {
        padding: '0 12px'
      },
      inputType: {
        height: '24px'
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '10px 12px'
      },
      root: {
        borderRadius: 4,
        '&$focused $notchedOutline': {
          borderColor: brandColors.info
        }
      }
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: brandColors.info
        }
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(23px, 22px) scale(1)',
        '&$shrink': {
          transform: 'translate(23px, 5px) scale(0.75)'
        }
      }
    },
    MuiInputAdornment: {
      root: {
        marginTop: 'auto',
        marginBottom: 'auto'
      }
    },
    MuiSelect: {
      select: {
        paddingRight: 22
      }
    },
    MuiFormControl: {
      root: {
        padding: 10,
        display: 'block'
      }
    },
    MuiFormControlLabel: {
      root: {
        padding: 10,
        height: 42
      }
    },
    MuiPaper: {
      root: {
        padding: 18
      },
      rounded: {
        borderRadius: 5
      }
    },
    MuiMenu: {
      paper: {
        padding: 8
      }
    },
    MuiExpansionPanel: {
      root: {
        '&:last-child': {
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5
        },
        '&:first-child': {
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5
        }
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: 0,
        minHeight: 0,
        '&$expanded': {
          minHeight: 0
        }
      },
      content: {
        margin: 0,
        '&$expanded': {
          margin: 0
        }
      },
      expandIcon: {
        right: 0
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: '8px 0 0'
      }
    }
  }
});
export var globalStyles = toJss("\n    @global {\n        body {\n            padding-right: 0 !important;\n            color: ".concat(theme.palette.grey[800], ";\n            background: ").concat(theme.palette.grey[100], ";\n            font-family: Roboto;\n            overflow: hidden;\n        }\n        *,\n        *:before,\n        *:after {\n            box-sizing: border-box;\n        }\n        #root {\n            height: 100vh;\n        }\n        a, a:hover, a:visited, a:active, a:focus {\n            text-decoration: none;\n            color: ").concat(theme.palette.primary.main, "\n        }\n        :focus {\n            outline: none;\n        }\n        /* width */\n        ::-webkit-scrollbar {\n            width: 10px;\n            height: 10px;\n        }\n\n        /* Track */\n        ::-webkit-scrollbar-track {\n            opacity: 0;\n        }\n        \n        /* Handle */\n        ::-webkit-scrollbar-thumb {\n            opacity: 0.1;\n            border-radius: 5px;\n            background: rgba(119, 119, 119, 0.3);\n        }\n\n        /* Handle on hover */\n        ::-webkit-scrollbar-thumb:hover {\n            background: rgba(119, 119, 119, 1);\n            opacity: 1;\n        }\n    }\n"));
export default theme;