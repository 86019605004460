var __extensible_get__ = require("extensible-runtime").immutable;

import axios from 'axios';
export var updateDriverDockGroup = function updateDriverDockGroup(payload) {
  return axios.put("/users/dockGroup", payload);
};
export var getDriverUserList = function getDriverUserList(payload) {
  var name = payload.name ? "keyword=".concat(payload.name, "&") : '';
  var status = payload.status >= 0 ? "status=".concat(payload.status, "&") : '';
  var roles = payload.roles.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, role) {
    return acc + "roles=".concat(role, "&");
  }, '');
  var dockgroups = payload.dockgroups.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, dockgroup) {
    return acc + "dockGroups=".concat(dockgroup, "&");
  }, '');
  var isRequestFromDriverDockGroup = "isRequestFromDriverDockGroup=".concat(payload.isRequestFromDriverDockGroup, "&");
  var skip = "skip=".concat(payload.skip, "&");
  var take = "take=".concat(payload.take, "&");
  var query = name + status + roles + dockgroups + skip + take + isRequestFromDriverDockGroup;
  return axios.get("/users".concat(query.length ? '?' : '').concat(query.slice(0, query.length - 1)));
};