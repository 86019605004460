var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
/**method used for setting filters */

export var initFilters = function initFilters() {
  return Immutable.fromJS({
    roles: [1],
    name: '',
    skip: 0,
    take: 10,
    status: 1,
    dockgroups: [0],
    isRequestFromDriverDockGroup: true
  });
};
/**method used for transforming payload response from api */

export var initPayload = function initPayload() {
  return {
    FirstName: '',
    LastName: '',
    ID: '',
    DockGroupID: 0
  };
};
/**method used for sending request for listing data along with filters in sagas */

export var getDriversList = function getDriversList() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initFilters;
  return {
    roles: obj.get('roles'),
    name: obj.get('name'),
    dockgroups: obj.get('dockgroups'),
    skip: obj.get('skip'),
    take: obj.get('take'),
    status: obj.get('status'),
    isRequestFromDriverDockGroup: obj.get('isRequestFromDriverDockGroup')
  };
};
/**method to transform the update driver payload before http request */

export var updateDockGroupDriver = function updateDockGroupDriver() {
  var _obj$get, _obj$get2;

  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initObj;
  return {
    UserId: (_obj$get = obj.get('UserId')) !== null && _obj$get !== void 0 ? _obj$get : '',
    DockGroupID: (_obj$get2 = obj.get('DockGroupID')) !== null && _obj$get2 !== void 0 ? _obj$get2 : ''
  };
};
/**method to set the update payload of driver dockgroup*/

export var setDriverObj = function setDriverObj(userId, dockgroupId) {
  return Immutable.fromJS({
    UserId: userId !== null && userId !== void 0 ? userId : '',
    DockGroupID: dockgroupId !== null && dockgroupId !== void 0 ? dockgroupId : ''
  });
};