var __extensible_get__ = require("extensible-runtime").immutable;

import axios from 'axios';
export var getMessageList = function getMessageList(payload) {
  var taskID = payload.taskID ? "taskID=".concat(payload.taskID) : '';
  var siteID = payload.siteID ? "siteID=".concat(payload.siteID) : '';
  var params = "".concat(siteID).concat(siteID.length && taskID.length ? '&' : '').concat(taskID);
  return axios.get("/messages".concat(params.length ? '?' : '').concat(params));
};
export var addMessage = function addMessage(payload) {
  return axios.post('/messages', payload);
};
export var deleteMessage = function deleteMessage(id) {
  return axios["delete"]("/messages/".concat(id));
};
export var pinMessage = function pinMessage(id) {
  return axios.put("/messages/".concat(id, "/pin"));
};