var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
export var types = {
  GET_SITE: 'GET_SITE',
  REQUEST_GET_SITE: 'REQUEST_GET_SITE',
  RECEIVE_GET_SITE: 'RECEIVE_GET_SITE',
  RECEIVE_GET_SITE_FAIL: 'RECEIVE_GET_SITE_FAIL',
  GET_SITE_LIST: 'GET_SITE_LIST',
  REQUEST_GET_SITE_LIST: 'REQUEST_GET_SITE_LIST',
  RECEIVE_GET_SITE_LIST: 'RECEIVE_GET_SITE_LIST',
  RECEIVE_GET_SITE_LIST_FAIL: 'RECEIVE_GET_SITE_LIST_FAIL',
  ADD_SITE: 'ADD_SITE',
  REQUEST_ADD_SITE: 'REQUEST_ADD_SITE',
  RECEIVE_ADD_SITE: 'RECEIVE_ADD_SITE',
  RECEIVE_ADD_SITE_FAIL: 'RECEIVE_ADD_SITE_FAIL',
  UPDATE_SITE: 'UPDATE_SITE',
  REQUEST_UPDATE_SITE: 'REQUEST_UPDATE_SITE',
  RECEIVE_UPDATE_SITE: 'RECEIVE_UPDATE_SITE',
  RECEIVE_UPDATE_SITE_FAIL: 'RECEIVE_UPDATE_SITE_FAIL',
  DELETE_SITE: 'DELETE_SITE',
  REQUEST_DELETE_SITE: 'REQUEST_DELETE_SITE',
  RECEIVE_DELETE_SITE: 'RECEIVE_DELETE_SITE',
  RECEIVE_DELETE_SITE_FAIL: 'RECEIVE_DELETE_SITE_FAIL',
  HANDLE_EDIT_SITE: 'HANDLE_EDIT_SITE',
  SET_EDITED_SITE: 'SET_EDITED_SITE',
  FETCH_SITE_LOGO: 'FETCH_SITE_LOGO',
  REQUEST_FETCH_SITE_LOGO: 'REQUEST_FETCH_SITE_LOGO',
  RECEIVE_FETCH_SITE_LOGO: 'RECEIVE_FETCH_SITE_LOGO',
  RECEIVE_FETCH_SITE_LOGO_FAIL: 'RECEIVE_FETCH_SITE_LOGO_FAIL',
  UPDATE_BANNER_VIEWED: 'UPDATE_BANNER_VIEWED'
};
export var actions = {
  getSite: function getSite(id) {
    return {
      type: types.GET_SITE,
      id: id
    };
  },
  requestGetSite: function requestGetSite() {
    return {
      type: types.REQUEST_GET_SITE
    };
  },
  receiveGetSite: function receiveGetSite(site) {
    return {
      type: types.RECEIVE_GET_SITE,
      site: site
    };
  },
  receiveGetSiteFail: function receiveGetSiteFail() {
    return {
      type: types.RECEIVE_GET_SITE_FAIL
    };
  },
  addSite: function addSite(payload, callback) {
    return {
      type: types.ADD_SITE,
      payload: payload,
      callback: callback
    };
  },
  requestAddSite: function requestAddSite() {
    return {
      type: types.REQUEST_ADD_SITE
    };
  },
  receiveAddSite: function receiveAddSite(site) {
    return {
      type: types.RECEIVE_ADD_SITE,
      site: site
    };
  },
  receiveAddSiteFail: function receiveAddSiteFail() {
    return {
      type: types.RECEIVE_ADD_SITE_FAIL
    };
  },
  updateSite: function updateSite(payload, callback) {
    return {
      type: types.UPDATE_SITE,
      payload: payload,
      callback: callback
    };
  },
  requestUpdateSite: function requestUpdateSite() {
    return {
      type: types.REQUEST_UPDATE_SITE
    };
  },
  receiveUpdateSite: function receiveUpdateSite(site) {
    return {
      type: types.RECEIVE_UPDATE_SITE,
      site: site
    };
  },
  receiveUpdateSiteFail: function receiveUpdateSiteFail() {
    return {
      type: types.RECEIVE_UPDATE_SITE_FAIL
    };
  },
  deleteSite: function deleteSite(id, callback) {
    return {
      type: types.DELETE_SITE,
      id: id,
      callback: callback
    };
  },
  requestDeleteSite: function requestDeleteSite() {
    return {
      type: types.REQUEST_DELETE_SITE
    };
  },
  receiveDeleteSite: function receiveDeleteSite(id) {
    return {
      type: types.RECEIVE_DELETE_SITE,
      id: id
    };
  },
  receiveDeleteSiteFail: function receiveDeleteSiteFail() {
    return {
      type: types.RECEIVE_DELETE_SITE_FAIL
    };
  },
  getSiteList: function getSiteList() {
    return {
      type: types.GET_SITE_LIST
    };
  },
  requestGetSiteList: function requestGetSiteList() {
    return {
      type: types.REQUEST_GET_SITE_LIST
    };
  },
  receiveGetSiteList: function receiveGetSiteList(sites) {
    return {
      type: types.RECEIVE_GET_SITE_LIST,
      sites: sites
    };
  },
  receiveGetSiteListFail: function receiveGetSiteListFail() {
    return {
      type: types.RECEIVE_GET_SITE_LIST_FAIL
    };
  },
  handleEditSite: function handleEditSite(key, value) {
    return {
      type: types.HANDLE_EDIT_SITE,
      key: key,
      value: value
    };
  },
  setEditedSite: function setEditedSite(site) {
    return {
      type: types.SET_EDITED_SITE,
      site: site
    };
  },
  getSiteLogo: function getSiteLogo(id) {
    return {
      type: types.FETCH_SITE_LOGO,
      id: id
    };
  },
  requestGetSiteLogo: function requestGetSiteLogo() {
    return {
      type: types.REQUEST_FETCH_SITE_LOGO
    };
  },
  receiveGetSiteLogo: function receiveGetSiteLogo(site) {
    return {
      type: types.RECEIVE_FETCH_SITE_LOGO,
      site: site
    };
  },
  receiveGetSiteLogoFail: function receiveGetSiteLogoFail() {
    return {
      type: types.RECEIVE_FETCH_SITE_LOGO_FAIL
    };
  },
  updateBannerViewed: function updateBannerViewed(callback) {
    return {
      type: types.UPDATE_BANNER_VIEWED,
      callback: callback
    };
  }
};
var initialState = Immutable.fromJS({
  list: [],
  currentSite: {},
  // this is not necesarily the active site
  editedSite: {
    TemperatureAlertRange: 0,
    Timezone: '',
    Managed: false,
    ManagerIDs: [],
    PlusSite: false,
    ActiveSiteRFID: false,
    HardTagSite: false,
    ProductProtectionPartialTrailer: false
  },
  listLoader: false,
  buttonLoader: false,
  formLoader: false,
  dialogLoader: false,
  siteLogo: '',
  bannerMessage: null
});

var receiveGetSiteList = function receiveGetSiteList(state, action) {
  return state.set('list', Immutable.fromJS(action.sites).sortBy(function (site) {
    return site.get('Name');
  }));
};

var receiveAddSite = function receiveAddSite(state, action) {
  return state.update('list', function (list) {
    return list.push(Immutable.fromJS(action.site));
  });
};

var receiveUpdateSite = function receiveUpdateSite(state, action) {
  var index = state.get('list').findIndex(function (site) {
    return site.get('ID') == action.site.ID;
  });

  if (action.site.ID === state.get('currentSite').get('ID')) {
    // update current/active site
    return state.setIn(['list', index], Immutable.fromJS(action.site)).set('currentSite', Immutable.fromJS(action.site)).set('editedSite', Immutable.fromJS(action.site));
  }

  return state.setIn(['list', index], Immutable.fromJS(action.site));
};

var receiveDeleteSite = function receiveDeleteSite(state, action) {
  var index = state.get('list').findIndex(function (site) {
    return site.get('ID') === action.id;
  });
  return state.update('list', function (list) {
    return list["delete"](index);
  });
};

var site = function site() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.REQUEST_GET_SITE:
      return state.set('formLoader', true);

    case types.RECEIVE_GET_SITE:
      return state.set('currentSite', Immutable.fromJS(action.site || {})) // avoid setting null
      .set('editedSite', Immutable.fromJS(action.site || {})).set('formLoader', false);

    case types.RECEIVE_GET_SITE_FAIL:
      return state.set('formLoader', false);

    case types.REQUEST_GET_SITE_LIST:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_SITE_LIST:
      return receiveGetSiteList(state, action).set('listLoader', false);

    case types.RECEIVE_GET_SITE_LIST_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_ADD_SITE:
      return state.set('buttonLoader', true);

    case types.RECEIVE_ADD_SITE:
      return receiveAddSite(state, action).set('buttonLoader', false);

    case types.RECEIVE_ADD_SITE_FAIL:
      return state.set('buttonLoader', false);

    case types.REQUEST_UPDATE_SITE:
      return state.set('dialogLoader', true);

    case types.RECEIVE_UPDATE_SITE:
      return receiveUpdateSite(state, action).set('dialogLoader', false);

    case types.RECEIVE_UPDATE_SITE_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_DELETE_SITE:
      return state.set('dialogLoader', true);

    case types.RECEIVE_DELETE_SITE:
      return receiveDeleteSite(state, action).set('dialogLoader', false);

    case types.RECEIVE_DELETE_SITE_FAIL:
      return state.set('dialogLoader', false);

    case types.HANDLE_EDIT_SITE:
      return state.setIn(['editedSite', action.key], action.value);

    case types.SET_EDITED_SITE:
      return state.set('editedSite', action.site);

    case types.REQUEST_FETCH_SITE_LOGO:
      return state.set('bigLoader', true);

    case types.RECEIVE_FETCH_SITE_LOGO:
      return state.set('siteLogo', action.site).set('bigLoader', false);

    case types.RECEIVE_FETCH_SITE_LOGO_FAIL:
      return state.set('bigLoader', false);

    default:
      return state;
  }
};

export var selectors = {
  getSites: function getSites(state) {
    return state.site.get('list');
  },
  getListLoader: function getListLoader(state) {
    return state.site.get('listLoader');
  },
  getButtonLoader: function getButtonLoader(state) {
    return state.site.get('buttonLoader');
  },
  getDialogLoader: function getDialogLoader(state) {
    return state.site.get('dialogLoader');
  },
  getFormLoader: function getFormLoader(state) {
    return state.site.get('formLoader');
  },
  getCurrentSite: function getCurrentSite(state) {
    return state.site.get('currentSite');
  },
  getEditedSite: function getEditedSite(state) {
    return state.site.get('editedSite');
  },
  getSiteById: function getSiteById(state, id) {
    return state.site.get('sites').find(function (site) {
      return site.get('ID') === id;
    });
  },
  getActiveSiteRFID: function getActiveSiteRFID(state) {
    return state.site.get('ActiveSiteRFID');
  },
  getLocationEditType: function getLocationEditType(state) {
    return state.site.get('LocationEditType');
  },
  getScale: function getScale(state) {
    return state.site.get('scale');
  },
  getSiteType: function getSiteType(state) {
    return state.site.get('SiteType');
  },
  getSiteLogo: function getSiteLogo(state) {
    return state.site.get('siteLogo');
  }
};
export default site;